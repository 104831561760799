import React from "react";
import { Header, Footer, Loader, ImageCropper } from "../../../Components";
import Accordion from "react-bootstrap/Accordion";
import { getFaqTypeList } from "../../../Redux/Services/OtherServices";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Container,
  Form,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
  ADD_BANNERS,
  ADD_BANNERS_DESCRIPTION,
  FAQ_TITLE,
} from "../../../Constants/MetaData";
import { getData, storageKey } from "../../../Utility/Storage";
import { addBanner, getBanners } from "../../../Redux/Services/AdminServices";
import { Image_URL } from "../../../Utility";
import SidebarLayout from "../../../layout/SidebarLayout";
import {
  ADD_BANNER,
  DELETE_BANNER,
  GET_BANNERS,
  UPDATE_BANNER,
} from "../../../API Services/Url";

export const AddBanners = () => {
  const dispatch = useDispatch();
  const [faqList, setFaqList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const [topBannerCrop, setTopBannerCrop] = useState(false);

  const allOptionData = useSelector((state) => state.authReducer.allOptionData);

  const [topBanners, setTopBanners] = useState([]);
  const [currentTopBanner, setCurrentTopBanner] = useState("");

  // State for bottom banner section
  const [bottomBanners, setBottomBanners] = useState({ title: "", uri: "" });

  // Modal state
  const [showTopBannerModal, setShowTopBannerModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editTopBannerImage, setEditTopBannerImage] = useState("");
  const [showBottomBannerModal, setShowBottomBannerModal] = useState(false);
  useEffect(() => {
    getAllTopBanners();
    // getAllBottomBanners();
  }, []);

  const getAllTopBanners = async () => {
    setLoading(true);
    let body = {
      image_type: "top_banner",
    };
    let res = await dispatch(getBanners(body, GET_BANNERS));
    if (res?.status == 200) {
      setTopBanners(res?.results);
      setLoading(false);
    }
  };
  const getAllBottomBanners = async () => {
    setLoading(true);
    let body = {
      image_type: "bottom_banner",
    };
    let res = await dispatch(getBanners(body, GET_BANNERS));
    if (res?.status == 200) {
      setLoading(false);
      setBottomBanners(res?.results);
    }
  };
  // console.log("bottomBanners-----", bottomBanners);

  // Add or Edit Top Banner
  const handleTopBannerSubmit = async (data) => {
    setLoading(true);
    let uploadData = new FormData();
    uploadData?.append("top_banner", "top_banner");
    uploadData.append("top_banner_image[]", data);

    let res = await dispatch(addBanner(uploadData, ADD_BANNER));
    if (res?.status == 200) {
      getAllTopBanners();
      setShowTopBannerModal(false);
      setCurrentTopBanner({ id: null, title: "", uri: "" });
      setSelectedFile([]);
      setLoading(false);
    }
  };

  const handleTopBannerEdit = async (data) => {
    setLoading(true);
    let uploadData = new FormData();
    uploadData?.append("image_id", editTopBannerImage?.id);
    uploadData?.append("top_banner", "top_banner");
    uploadData.append("top_banner_image", data);
    console.log("hiiioiiooio", uploadData);
    let res = await dispatch(addBanner(uploadData, UPDATE_BANNER));
    console.log("bannnnnnnnerrrrrrrr-------", res);

    if (res?.status == 200) {
      getAllTopBanners();
      setShowTopBannerModal(false);
      setCurrentTopBanner({ id: null, title: "", uri: "" });
      setSelectedFile([]);
      setEditTopBannerImage("");
      setLoading(false);
    }
  };

  // Edit Bottom Banner
  const handleBottomBannerSubmit = () => {
    setBottomBanners({ ...bottomBanners });
    setShowBottomBannerModal(false);
  };

  const handleTopBannerImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCurrentTopBanner(reader.result);
      };
      reader.readAsDataURL(file);
      setTopBannerCrop(true);
      setSelectedFile(file);
    }
  };

  const handleDeleteBanner = async () => {
    setLoading(true);
    let body = {
      image_id: currentTopBanner?.id,
    };
    let res = await dispatch(getBanners(body, DELETE_BANNER));
    if (res?.status == 200) {
      getAllTopBanners();
      setShowDeleteModal();
      setLoading(false);
    }
  };

  return (
    <SidebarLayout>
      <Loader loading={loading} />

      <Container>
        <div className="container">
          <div class="wt-userprofileholder acrdList">
            <h3>Banner Manager</h3>

            <div className="row">
              <div className="col-md-12 mt-2">
                <Accordion defaultActiveKey="0">
                  <>
                    <Accordion.Item eventKey={0}>
                      <Accordion.Header>
                        {"Top Hero Slider Banners"}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex row bnFlex">
                          {topBanners.map((banner) => (
                            <Card key={banner.id} className="banner-card">
                              <div className="image-container">
                                <Card.Img variant="top" src={banner.images} />
                                <div className="hover-buttons">
                                  <Button
                                    variant="warning"
                                    className="button"
                                    onClick={() => {
                                      setShowTopBannerModal(true);
                                      setEditTopBannerImage(banner);
                                      setCurrentTopBanner(banner);
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                      onClick={() =>
                                        setShowTopBannerModal(true)
                                      }
                                    ></i>
                                    {"  "}
                                    Edit
                                  </Button>
                                  <Button
                                    variant="light"
                                    className="button"
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setCurrentTopBanner(banner);
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                      onClick={() =>
                                        setShowTopBannerModal(true)
                                      }
                                    ></i>{" "}
                                    {"  "}
                                    Delete
                                  </Button>
                                </div>
                              </div>
                            </Card>
                          ))}
                        </div>
                      </Accordion.Body>
                      <div className="actIcon">
                        <i
                          className="fa fa-plus"
                          aria-hidden="true"
                          onClick={() => setShowTopBannerModal(true)}
                        ></i>
                      </div>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey={0}>
                      <Accordion.Header>
                        {"Top Hero Slider Banners"}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex row bnFlex">
                          {topBanners.map((banner) => (
                            <>
                              <Card key={banner.id}>
                                <Card.Img variant="top" src={banner.images} />
                                <Button
                                  variant="light"
                                  onClick={() => {
                                    setShowTopBannerModal(true);
                                    setEditTopBannerImage(banner);
                                    setCurrentTopBanner(banner);
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="light"
                                  onClick={() => {
                                    setShowTopBannerModal(true);
                                    setEditTopBannerImage(banner);
                                    setCurrentTopBanner(banner);
                                  }}
                                >
                                  Delete
                                </Button>
                              </Card>
                            </>
                          ))}
                        </div>
                      </Accordion.Body>
                      <div className="actIcon">
                        <i
                          class="fa fa-plus"
                          aria-hidden="true"
                          onClick={() => setShowTopBannerModal(true)}
                        ></i>
                      </div>
                    </Accordion.Item> */}
                    {/* <Accordion.Item eventKey={1}>
                      <Accordion.Header>{"Bottom Banners"}</Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex row bnFlex">
                          {bottomBanners.map((banner) => (
                            <>
                              <Card key={banner.id}>
                                <Card.Img variant="top" src={banner.images} />
                                <Button
                                  variant="light"
                                  onClick={() => {
                                    setShowTopBannerModal(true);
                                    setEditTopBannerImage(banner);
                                    setCurrentTopBanner(banner);
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="light"
                                  onClick={() => {
                                    setShowTopBannerModal(true);
                                    setEditTopBannerImage(banner);
                                    setCurrentTopBanner(banner);
                                  }}
                                >
                                  Delete
                                </Button>
                              </Card>
                            </>
                          ))}
                        </div>
                      </Accordion.Body>
                      <div className="actIcon">
                        <i
                          class="fa fa-plus"
                          aria-hidden="true"
                          onClick={() => setShowTopBannerModal(true)}
                        ></i>
                      </div>
                    </Accordion.Item> */}
                  </>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showTopBannerModal}
          onHide={() => setShowTopBannerModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {editTopBannerImage ? "Edit Banner" : "Add New Banner"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>
                  {editTopBannerImage ? "Update Image" : "Upload Image"}
                </Form.Label>
                {selectedFile.uri || editTopBannerImage?.images ? (
                  <img
                    src={selectedFile.uri || editTopBannerImage?.images}
                    alt="Preview"
                    className="img-thumbnail mb-3"
                  />
                ) : null}
                <Form.Control
                  type="file"
                  onChange={handleTopBannerImageUpload}
                />

                {currentTopBanner && (
                  <ImageCropper
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    imageSrc={currentTopBanner}
                    type={"banner"}
                    show={topBannerCrop}
                    setShow={setTopBannerCrop}
                    onCrop={
                      editTopBannerImage?.id
                        ? handleTopBannerEdit
                        : handleTopBannerSubmit
                    }
                  />
                )}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowTopBannerModal(false)}
            >
              Cancel
            </Button>
            {/* <Button variant="primary" onClick={handleTopBannerSubmit}>
              Save
            </Button> */}
          </Modal.Footer>
        </Modal>
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Banner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            Are you sure? You want to Delete this banner?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Not Now
            </Button>
            <Button variant="primary" onClick={() => handleDeleteBanner()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Bottom Banner Modal */}
      </Container>
    </SidebarLayout>
  );
};

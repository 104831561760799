import React, { useCallback, useEffect, useState } from "react";
import { Colors } from "../Constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../Redux/Services/AuthServices";
import { createSession } from "../Redux/Services/OtherServices";
import { LoginAlert } from "./Popups/LoginAlert";
import { InfoAlert, Login, StripeCardModal } from "./Popups";
import { getData, storageKey } from "../Utility/Storage";
import { useLocation, useNavigate } from "react-router-dom";
import { routeName, showToast } from "../Utility";
import { loggedInUsedAction } from "../Redux/Actions/AuthActions";

export const PlansCard = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const queryParams = new URLSearchParams(location?.search);
  const userIdPay = queryParams?.get("userID");
  const sessionId = queryParams?.get("session_id");
  const success = queryParams?.get("success");
  const { cardData, register, handleSubmit } = props;

  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const handleChoose = async () => {
    // if (register) {
    //   setShowCardForm(true);
    // } else {
    let userID = await getData(storageKey?.USER_ID);
    if (props?.setLoading) {
      props.setLoading(true);
    }
    if (userID) {
      let body = {
        product_id: cardData?.custom_product_id,
        price_id: cardData?.custom_price_id,
        user_id: userID ? JSON?.parse(userID) : "",
        action: "web_subscription_react",
      };

      let res = await dispatch(createSession(body));
      if (res?.status == 200) {
        if (window && res?.results?.url) {
          window.open(res?.results?.url, "_blank");
        } else {
          let body = {
            user_id: JSON?.parse(userID),
          };
          let res = await dispatch(getUserDetail(body));
          if (res?.status === 200) {
            dispatch(loggedInUsedAction(res?.results ? res?.results : null));
            if (props?.getAllPackages) {
              showToast("Package Purchased Successfully", "success");
              props?.getAllPackages();
            }
          }
        }
      }
      if (props?.setLoading) {
        props.setLoading(false);
      }
    } else {
      if (props?.setLoading) {
        props.setLoading(false);
      }
      setLoginModalVisible(true);
    }
    // }
  };

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));
      if (res?.status === 200) {
        dispatch(loggedInUsedAction(res?.results ? res?.results : null));
        navigate(routeName.HOME, { replace: true });
      }
    } else {
    }
  };

  const handlePayStatus = async () => {
    let body = {
      action: "session_react",
      user_id: userIdPay || "",
      sessions_id: sessionId || "",
    };
    let res = await dispatch(createSession(body));
    if (res?.status == 200) {
      getUserData();
      // showToast("Plan purchased successfully","success");
      // navigate(routeName.PACKAGES);
    }
  };

  const memoizedHandlePayStatus = useCallback(() => {
    if (userIdPay && sessionId && success) {
      handlePayStatus();
    }
  }, [userIdPay, sessionId, success]);

  useEffect(() => {
    memoizedHandlePayStatus();
  }, [memoizedHandlePayStatus]);

  return (
    <>
      <InfoAlert
        title="Order Now"
        text="Are you sure you want to buy this package?"
        show={show}
        setShow={setShow}
        setConfirm={setShow}
        onConfirm={handleChoose}
      />
      {/* <StripeCardModal
        open={showCardForm}
        setOpen={setShowCardForm}
        pacData={cardData}
        profileSubmit={handleSubmit}
      /> */}
      <div className="col-md-4">
        <div className="package-table">
          <div className="table-header">
            <h3>{cardData?.post_title}</h3>
            <h2>{`${
              cardData?.localizedPrice
                ? cardData?.localizedPrice
                : cardData?._price
                ? "$" + parseFloat(cardData?._price)
                : "$0"
            }`}</h2>
            <p style={{ color: Colors?.gray, fontWeight: 400 }}>
              {cardData?.description?.top_content}
            </p>
          </div>
          <div className="table-body">
            <ul className="features list-unstyled">
              {cardData?.description?.bottom?.map((item) => {
                return (
                  <>
                    <li style={{ color: Colors?.gray, fontWeight: 400 }}>
                      {item}
                    </li>
                  </>
                );
              })}
            </ul>
            <div
              className="table-footer"
              onClick={() => {
                if (props?.type === "edit") {
                  const queryParams = new URLSearchParams({
                    id: cardData?.product_id,
                  });
                  navigate(
                    `${routeName.ADD_PACKAGE}?${queryParams.toString()}`
                  );
                } else {
                  if (
                    loggedInUser?.user_data?.subscription_pro_id ==
                    cardData?.product_id
                  ) {
                  } else {
                    setShow(true);
                  }
                }
              }}
            >
              <a
                className={
                  loggedInUser?.user_data?.subscription_pro_id ==
                  cardData?.product_id
                    ? "signup btn disable"
                    : "signup btn"
                }
                style={{
                  opacity:
                    loggedInUser?.user_data?.subscription_pro_id ==
                    cardData?.product_id
                      ? 0.3
                      : 1,
                }}
              >
                {props?.type === "edit"
                  ? "Edit Package"
                  : loggedInUser?.user_data?.subscription_pro_id ==
                    cardData?.product_id
                  ? "Current Plan"
                  : "Order Now"}
              </a>
              {props?.type === "edit" ? (
                <a
                  className="viewSubscribers"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (props?.isDelete) {
                      const queryParams = new URLSearchParams({
                        id: cardData?.product_id,
                        name: cardData?.post_title,
                        price: cardData?.localizedPrice
                          ? cardData?.localizedPrice
                          : cardData?._price
                          ? "$" + parseFloat(cardData?._price)
                          : "$0",
                        // productId: cardData?.custom_product_id,
                        // priceId: cardData?.custom_price_id
                      });
                      navigate(
                        `${
                          routeName.SUBSCRIBERS_LIST
                        }?${queryParams.toString()}`
                      );
                    } else {
                      props?.setSelectedId(cardData?.product_id);
                      props?.setIsDeleteModal(true);
                    }
                  }}
                >
                  {props?.isDelete
                    ? (cardData?.subscribed_count || 0) + " Subscribers"
                    : "Delete"}
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {loginModalVisible && (
        <LoginAlert
          setVisible={setLoginModalVisible}
          visible={loginModalVisible}
          setLoginModal={setLoginModal}
        />
      )}

      {loginModal && (
        <Login
          show={loginModal}
          setShow={setLoginModal}
          getUserData={getUserData}
        />
      )}
    </>
  );
};

//import React, { useCallback, useEffect, useState } from "react";
// import { Colors } from "../Constants";
// import { useDispatch, useSelector } from "react-redux";
// import { getUserDetail } from "../Redux/Services/AuthServices";
// import { createSession } from "../Redux/Services/OtherServices";
// import { LoginAlert } from "./Popups/LoginAlert";
// import { InfoAlert, Login } from "./Popups";
// import { getData, storageKey } from "../Utility/Storage";
// import { useLocation, useNavigate } from "react-router-dom";
// import { routeName, showToast } from "../Utility";
// import { loggedInUsedAction } from "../Redux/Actions/AuthActions";

// export const PlansCard = (props) => {
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const navigate = useNavigate();

//   const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

//   const queryParams = new URLSearchParams(location?.search);
//   const userIdPay = queryParams?.get("userID");
//   const sessionId = queryParams?.get("session_id");
//   const success = queryParams?.get("success");
//   const { cardData } = props;

//   const [loginModalVisible, setLoginModalVisible] = useState(false);
//   const [loginModal, setLoginModal] = useState(false);
//   const [show, setShow] = useState(false);

//   const handleChoose = async () => {
//     let userID = await getData(storageKey?.USER_ID);
//     if (props?.setLoading) {
//       props.setLoading(true);
//     }
//     if (userID) {
//       let body = {
//         product_id: cardData?.custom_product_id,
//         price_id: cardData?.custom_price_id,
//         user_id: userID ? JSON?.parse(userID) : "",
//         action: "web_subscription_react",
//       };

//       let res = await dispatch(createSession(body));
//       if (res?.status == 200) {
//         if (window && res?.results?.url) {
//           window.open(res?.results?.url, "_blank");
//         } else {
//           let body = {
//             user_id: JSON?.parse(userID),
//           };
//           let res = await dispatch(getUserDetail(body));
//           if (res?.status === 200) {
//             dispatch(loggedInUsedAction(res?.results ? res?.results : null));
//             if (props?.getAllPackages) {
//               showToast("Package Purchased Successfully", "success");
//               props?.getAllPackages();
//             }
//           }
//         }
//       }
//       if (props?.setLoading) {
//         props.setLoading(false);
//       }
//     } else {
//       if (props?.setLoading) {
//         props.setLoading(false);
//       }
//       setLoginModalVisible(true);
//     }
//   };

//   const getUserData = async () => {
//     let userID = await getData(storageKey?.USER_ID);
//     if (userID) {
//       let body = {
//         user_id: JSON?.parse(userID),
//       };
//       let res = await dispatch(getUserDetail(body));
//       if (res?.status === 200) {
//         dispatch(loggedInUsedAction(res?.results ? res?.results : null));
//         navigate(routeName.HOME, { replace: true });
//       }
//     } else {
//     }
//   };

//   const handlePayStatus = async () => {
//     let body = {
//       action: "session_react",
//       user_id: userIdPay || "",
//       sessions_id: sessionId || "",
//     };
//     let res = await dispatch(createSession(body));
//     if (res?.status == 200) {
//       getUserData();
//       // showToast("Plan purchased successfully","success");
//       // navigate(routeName.PACKAGES);
//     }
//   };

//   const memoizedHandlePayStatus = useCallback(() => {
//     if (userIdPay && sessionId && success) {
//       handlePayStatus();
//     }
//   }, [userIdPay, sessionId, success]);

//   useEffect(() => {
//     memoizedHandlePayStatus();
//   }, [memoizedHandlePayStatus]);

//   return (
//     <>
//       <InfoAlert
//         title="Order Now"
//         text="Are you sure you want to buy this package?"
//         show={show}
//         setShow={setShow}
//         setConfirm={setShow}
//         onConfirm={handleChoose}
//       />
//       <div className="col-md-4">
//         <div className="table">
//           <div className="table-header">
//             <h3>{cardData?.post_title}</h3>
//             <h2>{`${
//               cardData?.localizedPrice
//                 ? cardData?.localizedPrice
//                 : cardData?._price
//                 ? "$" + parseFloat(cardData?._price)
//                 : "$0"
//             }`}</h2>
//             <p style={{ color: Colors?.gray, fontWeight: 400 }}>
//               {cardData?.description?.top_content}
//             </p>
//           </div>
//           <div className="table-body">
//             <ul className="features list-unstyled">
//               {cardData?.description?.bottom?.map((item) => {
//                 return (
//                   <>
//                     <li style={{ color: Colors?.gray, fontWeight: 400 }}>
//                       {item}
//                     </li>
//                   </>
//                 );
//               })}
//             </ul>
//             <div
//               className="table-footer"
//               onClick={() => {
//                 if (props?.type === "edit") {
//                   const queryParams = new URLSearchParams({
//                     id: cardData?.product_id,
//                   });
//                   navigate(
//                     `${routeName.ADD_PACKAGE}?${queryParams.toString()}`
//                   );
//                 } else {
//                   if (
//                     loggedInUser?.user_data?.subscription_pro_id ==
//                     cardData?.product_id
//                   ) {
//                   } else {
//                     setShow(true);
//                   }
//                 }
//               }}
//             >
//               <a
//                 className={
//                   loggedInUser?.user_data?.subscription_pro_id ==
//                   cardData?.product_id
//                     ? "signup btn disable"
//                     : "signup btn"
//                 }
//                 style={{
//                   opacity:
//                     loggedInUser?.user_data?.subscription_pro_id ==
//                     cardData?.product_id
//                       ? 0.3
//                       : 1,
//                 }}
//               >
//                 {props?.type === "edit"
//                   ? "Edit Package"
//                   : loggedInUser?.user_data?.subscription_pro_id ==
//                     cardData?.product_id
//                   ? "Current Plan"
//                   : "Order Now"}
//               </a>
//               {props?.type === "edit" ? (
//                 <a
//                   className="viewSubscribers"
//                   onClick={(e) => {
//                     e.stopPropagation();
//                     if (props?.isDelete) {
//                       const queryParams = new URLSearchParams({
//                         id: cardData?.product_id,
//                         name: cardData?.post_title,
//                         price: cardData?.localizedPrice
//                           ? cardData?.localizedPrice
//                           : cardData?._price
//                           ? "$" + parseFloat(cardData?._price)
//                           : "$0",
//                         // productId: cardData?.custom_product_id,
//                         // priceId: cardData?.custom_price_id
//                       });
//                       navigate(
//                         `${
//                           routeName.SUBSCRIBERS_LIST
//                         }?${queryParams.toString()}`
//                       );
//                     } else {
//                       props?.setSelectedId(cardData?.product_id);
//                       props?.setIsDeleteModal(true);
//                     }
//                   }}
//                 >
//                   {props?.isDelete
//                     ? (cardData?.subscribed_count || 0) + " Subscribers"
//                     : "Delete"}
//                 </a>
//               ) : (
//                 ""
//               )}
//             </div>
//           </div>
//         </div>
//       </div>

//       {loginModalVisible && (
//         <LoginAlert
//           setVisible={setLoginModalVisible}
//           visible={loginModalVisible}
//           setLoginModal={setLoginModal}
//         />
//       )}

//       {loginModal && (
//         <Login
//           show={loginModal}
//           setShow={setLoginModal}
//           getUserData={getUserData}
//         />
//       )}
//     </>
//   );
// };

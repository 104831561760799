import React, { useEffect, useState } from "react";
import SidebarLayout from "../../../layout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../Components";
import { isNumber, isString } from "../../../Utility/validations";
import { useFormik } from "formik";
import * as Yup from "yup";
import { routeName, showToast } from "../../../Utility";
import { useLocation, useNavigate } from "react-router-dom";
import EditText from "../../../Components/TextEditor";
import { addEditPackageApi } from "../../../Redux/Services/AdminServices";
import { Form } from "react-bootstrap";
import { getData, storageKey } from "../../../Utility/Storage";
import { getPackages } from "../../../Redux/Services/AuthServices";

const AddPackages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const memType = [
    {
      label: "plus",
      value: "plus",
    },
    {
      label: "pro",
      value: "pro",
    },
  ];

  const durationType = [
    {
      label: "monthly",
      value: "monthly",
    },
    {
      label: "yearly",
      value: "yearly",
    },
    {
      label: "weekly",
      value: "weekly",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [selectMembershipType, setSelectMembershipType] = useState("");
  const [selectedDurationType, setSelectedDurationType] = useState("");
  const [error, setError] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Caption is required"),
    // topDesc: Yup.string().required("Top Description is required"),
    price: Yup.string().required("Price is required"),
    connects: Yup.string().required("Number of connects is required"),
    noSkills: Yup.string().required("Number of skills is required"),
  });

  const convertToHTML = (bottomContent) => {
    return `<ul>${bottomContent
      ?.map((item) => `<li>${item?.trim()}</li>`)
      .join("")}</ul>`;
  };

  const getSinglePackageDetail = async () => {
    setLoading(true);
    let res = await dispatch(getPackages());
    setLoading(false);
    if (res?.status == 200) {
      let arr = res?.results?.length
        ? res?.results?.find((obj) => obj.product_id === id)
        : "";
      formik.setFieldValue("name", arr?.post_title || "");
      setDescription(
        arr?.description?.bottom ? convertToHTML(arr?.description?.bottom) : ""
      );
      formik.setFieldValue("topDesc", arr?.description?.top_content || "");
      formik.setFieldValue(
        "price",
        arr?.localizedPrice || arr?._regular_price || arr?._price || "0"
      );
      formik.setFieldValue("connects", arr?.wt_connects || "0");
      formik.setFieldValue("noSkills", arr?.wt_no_skills || "0");
      setSelectMembershipType(arr?.membership_type || "");
      setSelectedDurationType(
        arr?.wt_duration_type === "yearly"
          ? "yearly"
          : arr?.wt_duration_type === "monthly"
          ? "monthly"
          : arr?.wt_duration_type === "weekly"
          ? "weekly"
          : ""
      );
    }
  };

  const onSubmit = async (values) => {
    const userId = await getData(storageKey.USER_ID);
    setError(true);
    if (!formik.values.topDesc) {
      showToast("Top Description is required", "error");
      return;
    }
    if (!selectMembershipType) {
      showToast("Please select any membership type", "error");
      return;
    }
    if (!selectedDurationType) {
      showToast("Please select duration type", "error");
      return;
    }
    try {
      // console.log("Form values:", values);
      let body = {
        post_title: formik.values.name,
        bottom_description: description,
        top_description: formik.values.topDesc,
        package_type: "freelancer",
        membership_type: selectMembershipType,
        wt_connects: formik.values.connects,
        wt_duration_type: selectedDurationType,
        wt_no_skills: formik.values.noSkills,
        _price: formik.values.price,
        _regular_price: formik.values.price,
        user_id: userId ? JSON.parse(userId) : "",
      };
      if (id) {
        body.action = "update";
        body.product_id = id;
      }
      // console.log(body, "hfdsjfhskdfhds");
      setLoading(true);
      let res = await dispatch(addEditPackageApi(body));
      setLoading(false);
      setError(false);
      // console.log("identityVerification res---", res);
      if (res?.status === 200) {
        navigate(routeName.MANGE_PACKAGE, { replace: true });
      }
    } catch (error) {
      setError(false);
      setLoading(false);
      console.error("API request failed:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      topDesc: "",
      price: "",
      noSkills: "",
      connects: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    if (id) {
      getSinglePackageDetail();
    }
  }, []);

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <main>
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          <div className=" idVerification">
            <div className="col-md-8">
              <form
                onSubmit={formik.handleSubmit}
                className="post-identity-form wt-haslayout wt-attachmentsholder"
              >
                <div className="wt-dashboardbox">
                  <div className="wt-dashboardboxtitle wt-titlewithsearch">
                    <h2>{id ? "Update Package" : "Add Package"}</h2>
                  </div>
                  <div className="wt-dashboardboxcontent">
                    <div className="wt-helpsupportcontents">
                      <div className="form-grou">
                        <div className="wt-formtheme wt-formidentityinfo wt-formprojectinfo wt-formcategory wt-userform">
                          <fieldset>
                            <div className="form-group ">
                              <h2>Title</h2>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Write a Title"
                                onChange={(val) => {
                                  if (
                                    val.target.value === " " ||
                                    val.target.value === "."
                                  ) {
                                  } else if (isString(val.target.value)) {
                                    formik.handleChange(val);
                                  }
                                }}
                                value={formik.values.name}
                              />
                              {formik.errors.name && formik.touched.name && (
                                <div className="error">
                                  {formik.errors.name}
                                </div>
                              )}
                            </div>

                            <div className="form-group ">
                              <h2>Top Description</h2>
                              <textarea
                                // type="text"
                                name="topDesc"
                                className="form-control"
                                placeholder="Write a Description"
                                onChange={(val) => {
                                  if (
                                    val.target.value === " " ||
                                    val.target.value === "."
                                  ) {
                                  } else if (isString(val.target.value)) {
                                    formik.handleChange(val);
                                  }
                                }}
                                value={formik.values.topDesc}
                              ></textarea>
                              {formik.errors.topDesc &&
                                formik.touched.topDesc && (
                                  <div className="error">
                                    {formik.errors.topDesc}
                                  </div>
                                )}
                            </div>

                            <div className="form-group ">
                              <h2>Price</h2>
                              <input
                                type="text"
                                name="price"
                                className="form-control"
                                placeholder="Enter Price"
                                onChange={(val) => {
                                  if (
                                    val.target.value === " " ||
                                    val.target.value === "."
                                  ) {
                                  } else if (isNumber(val.target.value)) {
                                    formik.handleChange(val);
                                  }
                                }}
                                value={formik.values.price}
                                disabled={id}
                              />
                              {formik.errors.price && formik.touched.price && (
                                <div className="error">
                                  {formik.errors.price}
                                </div>
                              )}
                            </div>

                            <div className="form-group ">
                              <h2>Number of jobs to apply</h2>
                              <input
                                type="text"
                                name="connects"
                                className="form-control"
                                placeholder="Enter Number of jobs to apply"
                                onChange={(val) => {
                                  if (
                                    val.target.value === " " ||
                                    val.target.value === "."
                                  ) {
                                  } else if (isNumber(val.target.value)) {
                                    formik.handleChange(val);
                                  }
                                }}
                                value={formik.values.connects}
                              />
                              {formik.errors.connects &&
                                formik.touched.connects && (
                                  <div className="error">
                                    {formik.errors.connects}
                                  </div>
                                )}
                            </div>

                            <div className="form-group ">
                              <h2>Number of Portfolios</h2>
                              <input
                                type="text"
                                name="noSkills"
                                className="form-control"
                                placeholder="Enter Number of Portfolios"
                                onChange={(val) => {
                                  if (
                                    val.target.value === " " ||
                                    val.target.value === "."
                                  ) {
                                  } else if (isNumber(val.target.value)) {
                                    formik.handleChange(val);
                                  }
                                }}
                                value={formik.values.noSkills}
                              />
                              {formik.errors.noSkills &&
                                formik.touched.noSkills && (
                                  <div className="error">
                                    {formik.errors.noSkills}
                                  </div>
                                )}
                            </div>

                            <div className="form-group ">
                              <h2>Number of Social Posts</h2>
                              <input
                                type="text"
                                name="noSkills"
                                className="form-control"
                                placeholder="Enter Number of Social Posts"
                                onChange={(val) => {
                                  if (
                                    val.target.value === " " ||
                                    val.target.value === "."
                                  ) {
                                  } else if (isNumber(val.target.value)) {
                                    formik.handleChange(val);
                                  }
                                }}
                                value={formik.values.noSkills}
                              />
                              {formik.errors.noSkills &&
                                formik.touched.noSkills && (
                                  <div className="error">
                                    {formik.errors.noSkills}
                                  </div>
                                )}
                            </div>

                            <div className="form-group">
                              <h2>Select MembershipType</h2>
                              <Form.Group>
                                <Form.Select
                                  className={"form-control"}
                                  onChange={(e) => {
                                    if (e.target.value === "") {
                                      setSelectMembershipType("");
                                    } else {
                                      setSelectMembershipType(e.target.value);
                                    }
                                  }}
                                  value={selectMembershipType}
                                >
                                  <option value="">
                                    Select Membership Type
                                  </option>
                                  {memType?.length &&
                                    memType?.map((item, index) => {
                                      return (
                                        <>
                                          <option
                                            value={item?.value}
                                            label={item?.label}
                                          >
                                            {item?.label}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Form.Select>
                                {error && !selectMembershipType ? (
                                  <span
                                  // style={{
                                  //   color: "#dc3545",
                                  //   fontSize: 11,
                                  //   position: "absolute",
                                  //   bottom: -20,
                                  // }}
                                  >
                                    This field is required !
                                  </span>
                                ) : null}
                              </Form.Group>
                            </div>

                            <div className="form-group">
                              <h2>Select Duration</h2>
                              <Form.Group>
                                <Form.Select
                                  className={"form-control"}
                                  onChange={(e) => {
                                    if (e.target.value === "") {
                                      setSelectedDurationType("");
                                    } else {
                                      setSelectedDurationType(e.target.value);
                                    }
                                  }}
                                  value={selectedDurationType}
                                >
                                  <option value="">Select Duration</option>
                                  {durationType?.length &&
                                    durationType?.map((item, index) => {
                                      return (
                                        <>
                                          <option
                                            value={item?.value}
                                            label={item?.label}
                                          >
                                            {item?.label}
                                          </option>
                                        </>
                                      );
                                    })}
                                </Form.Select>
                                {error && !selectedDurationType ? (
                                  <span
                                  // style={{
                                  //   color: "#dc3545",
                                  //   fontSize: 11,
                                  //   position: "absolute",
                                  //   bottom: -20,
                                  // }}
                                  >
                                    This field is required !
                                  </span>
                                ) : null}
                              </Form.Group>
                            </div>

                            <div className="form-group">
                              <h2>Bottom Description</h2>
                              {loading ? undefined : (
                                <EditText
                                  content={description}
                                  setContent={setDescription}
                                  placeholder={"Blog Description"}
                                />
                              )}
                            </div>

                            <button
                              type="submit"
                              className="wt-btn wt-save-identity"
                              onClick={() => setError(true)}
                            >
                              Submit
                            </button>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </SidebarLayout>
  );
};

export default AddPackages;

import { createContext, useEffect, useState } from "react";
import { auth, db } from "../Utility/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  // Update user status
  const updateUserStatus = async (status) => {
    if (currentUser) {
      const userId = currentUser.uid;
      try {
        await setDoc(
          doc(db, "userStatus", userId),
          { status },
          { merge: true }
        );
      } catch (error) {
        console.error("Error updating status:", error.message);
      }
    }
  };

  // Logout function
  const handleFirebaseLogout = async () => {
    await updateUserStatus("offline");
    await signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        updateUserStatus("online");
      } else {
        setCurrentUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, handleFirebaseLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Filename - ContactUs.jsx
import React, { useState } from "react";
import { Header, Footer, Loader } from "../../Components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Colors, Images } from "../../Constants";
import { initLightboxJS } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import { SlideshowLightbox } from "lightbox.js-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserDetail } from "../../Redux/Services/AuthServices";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  convertUTCToLocalTime,
  routeName,
  showToast,
  timeSince,
} from "../../Utility";
import { getData, storageKey } from "../../Utility/Storage";
import {
  blockUser,
  getPostedListing,
  getProjectSingleClientList,
  savePostApi,
  unsavePostApi,
  userFollowing,
  userReporting,
} from "../../Redux/Services/OtherServices";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getOptionsData } from "../../Redux/Services/AuthServices";
import { LoginAlert } from "../../Components/Popups/LoginAlert";
import Rating from "@mui/material/Rating";
import { Login } from "../../Components/Popups";
import { getPartnerListApi } from "../../Redux/Services/PartnerServices";
import { Tab, Tabs } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TooltipMui from "@mui/material/Tooltip";
import { useHandleMessage } from "../../Utility/helper";
import { Helmet } from "react-helmet";
import {
  CLIENT_PROFILE_DESCRIPTION,
  CLIENT_PROFILE_TITLE,
} from "../../Constants/MetaData";
export const ClientProfile = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");
  // console.log("Your Param Value:", id);

  const handleMessage = useHandleMessage();

  const [lightbox, setlightbox] = useState(false);
  const [slideIndex, setSlideIndex] = useState(-1);
  const [optionsData, setOptionsData] = useState("");
  const [reportDescription, setReportDescription] = useState("");
  const [reportReason, setReportReason] = useState("");
  const [talentData, setTalentData] = useState("");
  const [isFollow, setIsFollow] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [jobDetails, setJobDetails] = useState("");
  const [isReadMore, setIsReadMore] = useState(false);
  const [tab, setTab] = useState("talent");
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const options = [
    {
      name: "Model",
      type: "talent",
    },
    {
      name: "Actor",
      type: "actor",
    },
    {
      name: "Photographer",
      type: "photographer",
    },
    {
      name: "Client",
      type: "client",
    },
  ];

  var Gallery = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    centerPadding: "20px",
  };

  var Actor = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const getTalentDetail = async () => {
    let user = await getData(storageKey.USER_ID);
    let body = {
      user_id: id,
    };
    let res = await dispatch(getUserDetail(body));
    if (res?.status == 200) {
      setTalentData({
        ...talentData,
        fwOption: res?.results?.fw_option ? res?.results?.fw_option?.[0] : "",
        userData: res?.results?.user_data || "",
        companyDetails: res?.results?.company_details || "",
        postDetails: res?.results?.post_meta_details || "",
        socialFollowers: res?.results?.social_followers || "",
        measurement: res?.results?.measurment || "",
        imageGallery: res?.results?.images_gallery || "",
        profileImage: res?.results?.profile_image || "",
        projectCount: res?.results?.project_count_details || "",
        companyDetails: res?.results?.company_details || "",
      });
      setIsFollow(
        res?.results?.post_meta_details?.user_followers?.includes(user)
          ? true
          : false
      );
      setIsBlock(
        res?.results?.user_data?.user_blocked_by_list?.length &&
          res?.results?.user_data?.user_blocked_by_list?.some(
            (item) => item?.id === user
          )
          ? true
          : false
      );
      if (res?.results?.user_data?.user_role === 14) {
        getAllPartnerList(res?.results?.user_data?.profile_id);
      }
    }
  };

  const getClientJobs = async () => {
    let user = await getData(storageKey.USER_ID);
    let body = {
      user_id: id,
      page_number: "1",
      per_page: "10",
    };
    let res = await dispatch(getProjectSingleClientList(body));
    if (res?.status == 200) {
      setJobDetails(res?.results?.list || []);
    }
  };

  const getAllFiltersData = async () => {
    let res = await dispatch(getOptionsData());
    if (res?.status === 200) {
      setOptionsData(res?.results?.user_report_options || "");
    }
  };

  useEffect(() => {
    if (id) {
      getTalentDetail();
      getClientJobs();
    }
  }, [id]);

  useEffect(() => {
    getAllFiltersData();
  }, []);

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));
    } else {
    }
  };

  const handleBlockUser = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      let body = {
        user_id: user,
        post_id: talentData?.userData?.user_id,
        action: isBlock ? "unblock" : "block",
      };
      let res = await dispatch(blockUser(body));
      if (res?.status == 200) {
        getTalentDetail();
        // showToast("Block succesfully");
      }
    } else {
      setLoginModalVisible(true);
      // showToast('You need to login to [erform this action!')
    }
  };

  const handleFollowUnFollow = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      let body = {
        user_id: user,
        post_id: talentData?.userData?.profile_id,
        action: isFollow ? "unfollow" : "follow",
      };
      let res = await dispatch(userFollowing(body));
      if (res?.status == 200) {
        getTalentDetail();
        showToast(
          isFollow ? "UnFollow Successfully" : "Follow Successfully",
          "success"
        );
      }
    } else {
      setLoginModalVisible(true);
      // showToast('You need to login to perform this action!')
    }
  };

  const handleReport = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      if (!reportReason) {
        showToast("Choose report reason first");
        return;
      }
      let body = {
        user_by: JSON.parse(user),
        reported_user_id: talentData?.userData?.user_id,

        report_type: "freelancer",
        reported_post_id: talentData?.userData?.profile_id,

        title: reportReason,
        content: reportDescription || "",
      };
      let res = await dispatch(userReporting(body));
      if (res?.status == 200) {
        setReportReason("");
        setReportDescription("");
      }
    } else {
      setLoginModalVisible(true);
      // showToast('You need to login to perform this action!')
    }
  };

  const getAllPartnerList = async (profile_id) => {
    setLoading(true);
    let body = {
      user_id: profile_id,
      user_type: tab,
      action: "registered",
      page_number: "1",
      per_page: "50",
    };
    let res = await dispatch(getPartnerListApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setList(res?.results?.user_data);
    } else {
      setList([]);
    }
  };

  useEffect(() => {
    if (talentData && talentData?.userData?.user_role === 14) {
      getAllPartnerList(talentData?.userData?.profile_id);
    }
  }, [talentData, tab]);

  return (
    <div>
      <Header />
      <Loader loading={loading} />
      <div className="profileSleder">
        {talentData?.imageGallery?.length ? (
          <Slider {...Gallery}>
            {talentData?.imageGallery?.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setlightbox(true);
                    setSlideIndex(index);
                  }}
                >
                  <img src={item?.url} />
                </div>
              );
            })}
          </Slider>
        ) : undefined}
      </div>

      {talentData?.imageGallery?.length ? (
        <SlideshowLightbox
          open={lightbox}
          startingSlideIndex={slideIndex}
          onClose={() => {
            setlightbox(false);
          }}
          className="lightBoxHIde"
        >
          {talentData?.imageGallery?.map((item, index) => {
            return <img key={index} src={item?.url} />;
          })}
        </SlideshowLightbox>
      ) : undefined}

      <div className="talentInfo">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="wt-userprofileholder">
                <div className="row">
                  {talentData?.postDetails?.subscription_pro_id != 107 ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id={`tooltip-${talentData?.postDetails?.subscription_pro_id}`}
                        >
                          {talentData?.postDetails?.subscription_pro_id ==
                            106 ||
                          talentData?.postDetails?.subscription_pro_id == 104
                            ? "Professional member"
                            : talentData?.postDetails?.subscription_pro_id ==
                                103 ||
                              talentData?.postDetails?.subscription_pro_id ==
                                105
                            ? "Premium member"
                            : ""}
                        </Tooltip>
                      }
                    >
                      {talentData?.postDetails?.subscription_pro_id ? (
                        <div
                          className={
                            talentData?.postDetails?.subscription_pro_id ==
                              106 ||
                            talentData?.postDetails?.subscription_pro_id == 104
                              ? "paidUsers pro"
                              : talentData?.postDetails?.subscription_pro_id ==
                                  103 ||
                                talentData?.postDetails?.subscription_pro_id ==
                                  105
                              ? "paidUsers"
                              : ""
                          }
                        >
                          <i class="fas fa-crown"></i>
                        </div>
                      ) : (
                        <></>
                      )}
                    </OverlayTrigger>
                  ) : null}
                  {/* <div class="paidUsers">
                    <i class="fas fa-crown"></i>
                  </div> */}
                  <div class="col-3">
                    <div class="row">
                      <div class="wt-userprofile">
                        {talentData?.profileImage?.length ? (
                          <figure>
                            <img
                              src={
                                talentData?.profileImage?.[0]?.guid ||
                                Images?.hero1
                              }
                              alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                            />
                            {/* <div class="wt-userdropdown wt-online"></div> */}
                          </figure>
                        ) : undefined}
                        <div class="wt-title">
                          <h3>
                            {talentData?.userData?.identity_status === 2 ? (
                              <a href="javascript:void(0);">
                                <i
                                  class="fa fa-check-circle"
                                  title="Identity Verified
                              "
                                ></i>
                              </a>
                            ) : (
                              <a href="javascript:void(0);">
                                <i
                                  class="fa fa-times-circle"
                                  title="Identity
                          not verified"
                                ></i>
                              </a>
                            )}
                            {`${talentData?.userData?.first_name || ""} ${
                              talentData?.userData?.last_name?.slice(0, 1) || ""
                            }`}
                          </h3>

                          <div className="ratingProfile">
                            <Rating
                              name="half-rating-read"
                              precision={0.5}
                              value={
                                talentData?.postDetails?.user_rating
                                  ? Number(talentData?.postDetails?.user_rating)
                                  : 0
                              }
                              readOnly
                            />
                          </div>

                          <span>
                            {`${
                              talentData?.postDetails?.user_rating
                                ? Number(talentData?.postDetails?.user_rating)
                                : 0
                            }/5`}
                            {/* <a class="javascript:void(0);">(860 Feedback)</a> */}
                            <p>{`Member since ${moment(
                              talentData?.userData?.post_date
                            ).format("MMM DD,YYYY")}`}</p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-9">
                    <div class="wt-proposalhead wt-userdetails">
                      <h2>{talentData?.fwOption?.tag_line || ""}</h2>
                      <ul class="wt-userlisting-breadcrumb wt-userlisting-breadcrumbvtwo">
                        <li>
                          <span>{talentData?.postDetails?.state || ""}</span>
                        </li>
                        <li>{talentData?.postDetails?.city || ""}</li>
                      </ul>
                      <div
                        class="wt-description"
                        data-readmore=""
                        aria-expanded="false"
                        id="rmjs-1"
                      >
                        {talentData?.companyDetails?.description ? (
                          <>
                            <span>About:</span>
                            {/* <p>
                              {talentData?.companyDetails?.description || ""}
                            </p> */}
                            {talentData?.companyDetails?.description &&
                            talentData?.companyDetails?.description?.length >
                              400 ? (
                              <>
                                <p>
                                  {isReadMore
                                    ? talentData?.companyDetails?.description
                                    : talentData?.companyDetails?.description?.slice(
                                        0,
                                        400
                                      ) || ""}
                                  {!isReadMore &&
                                  talentData?.companyDetails?.description
                                    ?.length > 400 ? (
                                    <span
                                      className="read_btn"
                                      onClick={() => setIsReadMore(!isReadMore)}
                                    >
                                      ...Read More
                                    </span>
                                  ) : talentData?.companyDetails?.description
                                      ?.length > 400 ? (
                                    <span
                                      className="read_btn"
                                      onClick={() => setIsReadMore(!isReadMore)}
                                    >
                                      Read Less
                                    </span>
                                  ) : undefined}
                                </p>
                              </>
                            ) : talentData?.companyDetails?.description &&
                              talentData?.companyDetails?.description?.length <
                                400 ? (
                              <>
                                <p>
                                  {talentData?.companyDetails?.description ||
                                    ""}
                                </p>
                              </>
                            ) : undefined}
                          </>
                        ) : undefined}
                      </div>
                    </div>
                    <div
                      id="wt-statistics"
                      class="wt-statistics wt-profilecounter"
                    >
                      <div class="wt-statisticcontent wt-countercolor1">
                        <h3
                          data-from="0"
                          data-to="03"
                          data-speed="800"
                          data-refresh-interval="03"
                        >
                          {talentData?.projectCount?.ongoing_project || 0}
                        </h3>
                        <h4>Ongoing Projects</h4>
                      </div>
                      <div class="wt-statisticcontent wt-countercolor2">
                        <h3
                          data-from="0"
                          data-to="1503"
                          data-speed="8000"
                          data-refresh-interval="100"
                        >
                          {talentData?.projectCount?.complete_project || 0}
                        </h3>
                        <h4>Completed Projects</h4>
                      </div>
                      <div class="wt-statisticcontent wt-countercolor4">
                        <h3
                          data-from="0"
                          data-to="02"
                          data-speed="800"
                          data-refresh-interval="02"
                        >
                          {talentData?.projectCount?.cancelled_project || 0}
                        </h3>
                        <h4>Cancelled Projects</h4>
                      </div>
                      <div class="wt-statisticcontent wt-countercolor3">
                        <h3
                          data-from="0"
                          data-to="25"
                          data-speed="8000"
                          data-refresh-interval="100"
                        >
                          {talentData?.postDetails?.user_followers?.length || 0}
                        </h3>
                        {/* <em>k</em> */}
                        <h4>Followers</h4>
                      </div>
                      {loggedInUser?.user_data?.user_id &&
                      loggedInUser?.user_data?.user_id !== id ? (
                        <div class="wt-profile-btn">
                          <a class="wt-btn">
                            {isFollow ? "UnFollow" : "Follow"}
                          </a>
                          <a
                            onClick={() => {
                              handleMessage(
                                talentData?.userData?.user_email,
                                talentData?.userData?.firebase_udi,
                                talentData?.userData?.full_name,
                                setLoading,
                                talentData?.profileImage?.[0]?.guid,
                                id
                              );
                            }}
                            class="wt-btn"
                          >
                            Message
                          </a>
                          <a onClick={handleBlockUser} class="wt-btn">
                            {isBlock ? "UnBlock" : "Block"}
                          </a>
                        </div>
                      ) : undefined}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-md-8">
              <div class="talentDescription">
                <p>
                  <span> Company Name :</span>
                  <span>{talentData?.companyDetails?.organization || ""}</span>
                </p>
                <p>
                  <span> Business Website :</span>
                  <span>
                    {talentData?.companyDetails?.bussiness_website || ""}
                  </span>
                </p>
                <p>
                  <span> Title :</span>
                  <span>{talentData?.companyDetails?.title || ""}</span>
                </p>
                <p>
                  <span> We have been in bussiness since :</span>
                  <span>
                    {talentData?.companyDetails?.bussiness_since || ""}
                  </span>
                </p>
              </div>

              <section class="joblisting">
                {jobDetails?.length ? (
                  <h3 className="wt-titlereact">Posted Jobs</h3>
                ) : undefined}
                {jobDetails?.length
                  ? jobDetails?.map((item, index) => {
                      return (
                        <>
                          <JobCard
                            cardData={item}
                            handleApplyFilter={getClientJobs}
                          />
                        </>
                      );
                    })
                  : undefined}
              </section>
            </div>
            <div class="col-md-4">
              <div className="rightSideBar">
                {talentData?.socialFollowers?.facebook_profile_link ||
                talentData?.socialFollowers?.youtube_profile_link ||
                talentData?.socialFollowers?.instagram_profile_link ? (
                  <h4>Social Media Handles</h4>
                ) : undefined}
                <ul class="socialIcon">
                  {talentData?.socialFollowers?.facebook_profile_link ? (
                    <li>
                      <a
                        href={
                          talentData?.socialFollowers?.facebook_profile_link
                        }
                        target="_blank"
                      >
                        <i class="fa fa-facebook"></i>{" "}
                        {/* <span>{`${talentData?.socialFollowers?.facebook_follower} Followers`}</span> */}
                      </a>
                    </li>
                  ) : undefined}
                  {talentData?.socialFollowers?.instagram_profile_link ? (
                    <li>
                      <a
                        href={
                          talentData?.socialFollowers?.instagram_profile_link
                        }
                        target="_blank"
                      >
                        <i class="fa fa-instagram"></i>{" "}
                        {/* <span>{`${talentData?.socialFollowers?.instagram_follower} Followers`}</span> */}
                      </a>
                    </li>
                  ) : undefined}
                  {talentData?.socialFollowers?.youtube_profile_link ? (
                    <li>
                      <a
                        href={talentData?.socialFollowers?.youtube_profile_link}
                        arget="_blank"
                        title="0-9k Follower"
                      >
                        <i class="fa fa-youtube"></i>{" "}
                        {/* <span>{`${talentData?.socialFollowers?.youtube_follower} Followers`}</span> */}
                      </a>
                    </li>
                  ) : undefined}
                </ul>

                {/* <h4>Report This Client</h4>
                <div class="wt-widgetcontent">
                  <form class="wt-formtheme wt-formreport">
                    <fieldset>
                      <div class="form-group">
                        <span class="wt-select">
                          <select
                            value={reportReason}
                            onChange={(e) => setReportReason(e.target.value)}
                          >
                            <option disabled value="">
                              Select Reason
                            </option>
                            {optionsData?.length
                              ? optionsData?.map((item) => (
                                  <option key={item?.label} value={item?.label}>
                                    {item?.value}
                                  </option>
                                ))
                              : undefined}
                          </select>
                        </span>
                      </div>
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          placeholder="Description"
                          value={reportDescription}
                          onChange={(e) => setReportDescription(e.target.value)}
                        ></textarea>
                      </div>
                      <div class="form-group wt-btnarea">
                        <a
                          onClick={handleReport}
                          
                          class="wt-btn"
                        >
                          Submit
                        </a>
                      </div>
                    </fieldset>
                  </form>
                </div>
                 */}
              </div>
            </div>
          </div>
        </div>
        {talentData?.userData?.user_role === 14 ? (
          <div className="dashboard_main">
            <div className="myJobs">
              <Tabs
                defaultActiveKey="posted"
                id="list"
                activeKey={tab}
                onSelect={(type) => {
                  setList([]);
                  setTab(type);
                }}
              >
                {options?.map((item, index) => (
                  <Tab key={index} eventKey={item?.type} title={item.name}>
                    {/* <div class="wt-managejobcontent">
                      <div className="cards"> */}
                    <section className="searchtalentList">
                      <div className="container">
                        <div className="row">
                          {list?.length &&
                            list?.map((item, index) => {
                              return (
                                <>
                                  <TalentCard cardData={item} tab={tab} />
                                </>
                              );
                            })}
                          {/* <TableContainer className="table_container">
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Phone no</TableCell>
                                <TableCell>Email</TableCell>
                              </TableRow>
                            </TableHead>
                            {list?.length ? (
                              list?.map((item, i) => {
                                return (
                                  <TableBody>
                                    <TableRow>
                                      <TableCell
                                        onClick={() => {
                                          if (
                                            tab === "talent" ||
                                            tab === "actor"
                                          ) {
                                            const queryParams =
                                              new URLSearchParams({
                                                id: item?.email_user_id,
                                              });
                                            navigate(
                                              `${
                                                routeName.TALENT_PROFILE
                                              }?${queryParams.toString()}`
                                            );
                                          } else {
                                            const queryParams =
                                              new URLSearchParams({
                                                id: item?.email_user_id,
                                              });
                                            navigate(
                                              `${
                                                routeName.CLIENT_PROFILE
                                              }?${queryParams.toString()}`
                                            );
                                          }
                                        }}
                                      >
                                        {`${item?.name || "-"}` || ""}
                                      </TableCell>
                                      <TableCell>
                                        {item?.phone || "-"}
                                      </TableCell>
                                      <TableCell>
                                        {item?.user_email || ""}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                );
                              })
                            ) : (
                              <TableBody>
                                <TableCell
                                  align="center"
                                  colSpan={10}
                                  sx={{ color: "black" }}
                                >
                                  {`No Data Found`}
                                </TableCell>
                              </TableBody>
                            )}
                          </Table>
                        </TableContainer> */}
                        </div>
                      </div>
                    </section>
                  </Tab>
                ))}
              </Tabs>
            </div>
          </div>
        ) : undefined}
      </div>

      {loginModalVisible && (
        <LoginAlert
          // title="hhiiii"
          text="hdfj"
          setVisible={setLoginModalVisible}
          visible={loginModalVisible}
          setLoginModal={setLoginModal}
          loginModal={loginModal}
        />
      )}

      {loginModal && (
        <Login
          show={loginModal}
          setShow={setLoginModal}
          getUserData={getUserData}
        />
      )}
      <Footer />
    </div>
  );
};

const JobCard = (props) => {
  const { cardData, handleApplyFilter } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const handleSaveJob = async (postId, status) => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      let body = {
        user_id: JSON.parse(user),
        post_id: postId,
        type: status == 1 ? "unsaved" : "saved",
        post_type: "213",
      };
      let res = "";
      if (status == 1) {
        res = await dispatch(unsavePostApi(body));
      } else {
        res = await dispatch(savePostApi(body));
      }
      if (res?.status == 200) {
        handleApplyFilter();
        showToast(
          status == 1 ? "Job Removed Successfully" : "Job Saved Successfully",
          "success"
        );
      }
    } else {
      setLoginModalVisible(true);
      // showToast('You need to login to perform this action!')
    }
  };

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      await dispatch(getUserDetail(body));
    }
  };

  return (
    <>
      <Helmet>
        <title>{CLIENT_PROFILE_TITLE}</title>
        <meta
          name="keywords"
          content={CLIENT_PROFILE_TITLE}
        />
        <meta name="description" content={CLIENT_PROFILE_DESCRIPTION} />
      </Helmet>
      <div class="wt-userlistinghold wt-userlistingholdvtwo">
        <div class="wt-userlistingcontent">
          <div class="wt-contenthead">
            <div class="wt-title">
              <div className="wrTitle">
                {/* <small>{moment(cardData?.profile?.post_date).fromNow()}</small> */}
                <small>
                  {timeSince(
                    convertUTCToLocalTime(cardData?.profile?.post_date)
                  )}
                </small>
                <h2>
                  {/* <img
                decoding="async"
                src={cardData?.profile_image}
                alt="Testaccount test"
              /> */}

                  {cardData?.profile?.post_title}
                </h2>
                {cardData?.post_meta_details?.model_type_req ===
                "casting calls" ? (
                  <small className="castingCalls">Casting calls</small>
                ) : undefined}
              </div>

              {/* <div onClick={() => handleSaveJob(cardData?.profile?.ID, cardData?.post_meta_details?.saved_status)} class="wt-clicklike">
                <i class={cardData?.post_meta_details?.saved_status == 1 ? 'fa fa-heart' : "fa fa-heart-o"}></i>
              </div> */}

              {/* <i class="fa-solid fa-heart"></i> */}

              <div class="wt-description">
                <p>
                  {cardData?.profile?.post_content && (
                    <p>{cardData?.profile?.post_content}</p>
                  )}
                </p>
              </div>
            </div>

            {/* <div class="wt-tag wt-widgettag">
              <a href="javascript:void(0);">PHP</a>
              <a href="javascript:void(0);">HTML</a>
              <a href="javascript:void(0);">JQuery</a>
            </div> */}
          </div>
          <div class="wt-viewjobholder">
            <ul>
              <li>
                <span>
                  <i class="fa fa-flag" aria-hidden="true"></i>{" "}
                  {cardData?.post_meta_details?.country}/{" "}
                  {cardData?.post_meta_details?.city}
                </span>
              </li>
              <li>
                <span>
                  <i class="fa fa-cogs" aria-hidden="true"></i>Expert: All level
                </span>
              </li>
              <li>
                <span>
                  <i class="fa fa-gavel" aria-hidden="true"></i>Proposal:{" "}
                  {cardData?.post_meta_details?.proposal_count || 0}
                </span>
              </li>
              <li>
                <span>
                  <i class="far fa-folder "></i>Type:{" "}
                  {cardData?.post_meta_details?._project_type}
                </span>
              </li>
              <li>
                <span>
                  <i class="fa fa-money "></i>Budget:{" "}
                  {/* {cardData?.post_meta_details?._hourly_rate
                    ? `Hourly Rate - $${cardData?.post_meta_details?._hourly_rate}`
                    : cardData?.post_meta_details?._estimated_hours
                    ? `Estimated Hours - $${cardData?.post_meta_details?._estimated_hours}`
                    : "0/hr"} */}
                  {cardData?.post_meta_details?.project_type?.project_cost
                    ? "$" +
                      cardData?.post_meta_details?.project_type?.project_cost
                    : cardData?.post_meta_details?._hourly_rate
                    ? "$" +
                      cardData?.post_meta_details?._hourly_rate +
                      " (" +
                      cardData?.post_meta_details?._estimated_hours +
                      " hours)"
                    : 0}
                </span>
              </li>
              <li class="wt-btnarea">
                <div
                  onClick={() => {
                    const queryParams = new URLSearchParams({
                      id: cardData?.profile?.ID,
                    });
                    navigate(
                      `${routeName.JOB_DETAILS}?${queryParams.toString()}`
                    );
                  }}
                  // onClick={() => {
                  //    navigate('/jobdetails', {replace: true})
                  // }}
                  class="wt-btn"
                >
                  View Job
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {loginModalVisible && (
        <LoginAlert
          // title="hhiiii"
          text="hdfj"
          setVisible={setLoginModalVisible}
          visible={loginModalVisible}
          setLoginModal={setLoginModal}
          loginModal={loginModal}
        />
      )}

      {loginModal && (
        <Login
          show={loginModal}
          setShow={setLoginModal}
          getUserData={getUserData}
        />
      )}
    </>
  );
};

const TalentCard = (props) => {
  const { cardData, tab } = props;
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");
  // console.log("cardDatacardData----", cardData?.profile_img?.url);
  return (
    <>
      <div className="talent_partner">
        <div
          className="talent_partner_section"
          onClick={() => {
            if (tab === "talent" || tab === "actor") {
              const queryParams = new URLSearchParams({
                id: cardData?.email_user_id,
              });
              navigate(`${routeName.TALENT_PROFILE}?${queryParams.toString()}`);
            } else {
              const queryParams = new URLSearchParams({
                id: cardData?.email_user_id,
              });
              navigate(`${routeName.CLIENT_PROFILE}?${queryParams.toString()}`);
            }
          }}
        >
          <img src={cardData?.profile_img?.url} className="talent_img" />

          <div className="talent_partner_info">
            <h3 style={{ color: "#fff", fontWeight: "600" }}>
              {cardData?.display_name}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

{
  /* <div className="rpc-post-image" >
<div className="fixed-height-image" >
<img src={cardData?.profile_img?.url} />
</div>
</div>
<a href="#" className="craContent" id="" tabindex="-1">
<div className="bottomArea ">
  <h3 className="rpc-post-title">
    <div className="rpc-title" target="_self">
      {cardData?.display_name}
    </div>
  </h3>

  <span>
    {" "}
    <i class="fa fa-envelope"></i>
    {"  "}
    {cardData?.user_email}
  </span>
  <div className="location">
    {" "}
    <i class="fa fa-phone"></i>
    {"   "}
    {cardData?.phone}
  </div>
</div>
</a> */
}

import React, { useContext, useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NotificationList from "./notificationList";
import { useDispatch, useSelector } from "react-redux";
import { routeName } from "../../Utility";
import { saveTalentFiltersAction } from "../../Redux/Actions/OtherActions";
import { removeData, storageKey } from "../../Utility/Storage";
import { partnerAccessPermissions } from "../../Redux/Actions/PartnerActions";
import { DeletePost } from "../../Components";
import { AuthContext } from "../../Context/AuthContext";
import {
  doc,
  onSnapshot,
  updateDoc,
  deleteDoc,
  deleteField,
  serverTimestamp,
  collection,
  getDoc,
  setDoc,
  getDocs,
  arrayUnion,
  writeBatch,
  query,
  where,
} from "firebase/firestore";
import { db, firebaseDB } from "../../Utility/firebase";
const AuthButton = (props) => {
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);

  const auth = useSelector((state) => state?.authReducer);
  const badgeCount = useSelector((state) => state.otherReducer.badgeCount);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const { partnerPermissions } = useSelector((state) => state.authReducer);

  const [isAccess, setIsAccess] = useState(false);
  const [permissions, setPermissions] = useState("");
  const [chatCount, setChatCount] = useState(0);

  const {
    notificationData,
    handleViewAction,
    dropItems,
    setShow,
    setLoginModal,
    userId,
    type,
    userRole,
    loading,
    setLoading,
    count,
    getNotifCount,
  } = props;

  useEffect(() => {
    if (partnerPermissions?.email) {
      setPermissions(partnerPermissions);
    }
  }, [partnerPermissions]);

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.uid) getChats();
  }, [currentUser?.uid]);

  const getChats = () => {
    const unsub = onSnapshot(
      doc(db, "userChats", currentUser?.uid),
      async (docs) => {
        const chatData = docs.data();
        if (chatData) { 
          Object.entries(chatData).forEach(async ([chatId, chat]) => {
            if (currentUser?.uid != chat.userInfo?.uid) {
              let counts = 0;
              counts += chat.unreadCount || 0;
              setChatCount(counts);
            }
          });
        }
      }
    );
    return () => unsub();
  };
  return (
    <Nav className="align-items-center">
      {type === "pc" ? (
        <>
          {(userRole != 11 || userRole !== 15) && (
            <Nav.Link onClick={() => navigate(routeName?.SEARCH_TALENT)}>
              Search Talent
            </Nav.Link>
          )}
          {(userRole != 12 || userRole !== 14) && (
            <Nav.Link
              onClick={() => {
                dispatch(saveTalentFiltersAction(null));
                navigate(routeName?.SEARCH_JOBS);
              }}
            >
              Search Jobs
            </Nav.Link>
          )}
          <Nav.Link onClick={() => navigate(routeName?.SCULP_FEED)}>
              Social Feed
            </Nav.Link>

          <Nav.Link onClick={() => navigate(routeName?.INSIGHTS)}>
            Insight
          </Nav.Link>
        </>
      ) : undefined}
      {loading ? undefined : userId && loggedInUser ? (
        <>
          <Nav.Link className="notification">
            <div class="wt-userlogedin wt-notificatoin ">
              <figure class="wt-userimg">
                {badgeCount != 0 ? (
                  <span class="notify-counter">{badgeCount || ""}</span>
                ) : undefined}
                <i class="fa fa-bell" aria-hidden="true"></i>
              </figure>
              <NotificationList
                notificationData={notificationData}
                handleViewAction={handleViewAction}
              />
            </div>
          </Nav.Link>
          <Nav.Link className="notification">
            <div class="wt-notificatoin ">
              <figure
                class="wt-userimg"
                onClick={() => navigate(routeName?.CHAT)}
              >
                {chatCount != 0 ? (
                  <span class="notify-counter">{chatCount}</span>
                ) : undefined}
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </figure>
            </div>
          </Nav.Link>
          <Nav.Link className="accountSection">
            <div class="wt-userlogedin">
              <figure class="wt-userimg">
                {auth?.isLoading ? null : loggedInUser &&
                  loggedInUser?.profile_image &&
                  loggedInUser?.profile_image?.length != 0 &&
                  loggedInUser?.profile_image[0]?.guid ? (
                  <img
                    src={loggedInUser?.profile_image?.[0]?.guid}
                    alt="image description"
                  />
                ) : (
                  <i
                    class="fa fa-user-circle user_profile"
                    aria-hidden="true"
                  ></i>
                )}
              </figure>
              <div class="wt-username">
                <h5>
                  {`${
                    partnerPermissions?.name
                      ? partnerPermissions?.name
                      : loggedInUser?.user_data?.first_name || ""
                  } ${loggedInUser?.user_data?.last_name?.slice(0, 1)}`}
                  {/* {loggedInUser?.user_data?.full_name
                    ? loggedInUser?.user_data?.full_name
                    : loggedInUser?.user_data?.first_name +
                      loggedInUser?.user_data?.last_name?.slice(0,1)} */}
                </h5>
                <span>
                  {loggedInUser?.company_details?.title
                    ? loggedInUser?.company_details?.title?.length > 15
                      ? `${loggedInUser?.company_details?.title?.slice(
                          0,
                          15
                        )}...`
                      : loggedInUser?.company_details?.title
                    : `${
                        loggedInUser?.post_meta_details?.tag_line?.slice(
                          0,
                          15
                        ) || ""
                      }...` || ""}
                </span>
              </div>

              {permissions?.role ? (
                <div
                  onClick={() => {
                    setIsAccess(true);
                  }}
                  className="exitAccess"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              ) : undefined}

              <nav class="wt-usernav">
                <ul>
                  {dropItems?.length != 0 &&
                    dropItems?.map((item, index) => {
                      return (
                        <>
                          {!item?.hide && (
                            <li
                              onClick={() => {
                                // dispatch(partnerAccessPermissions(null))
                                // removeData(storageKey.ACCESS_BASED);
                                // removeData(storageKey.ACCESS_TOKEN);
                                if (item?.key === 2) {
                                  navigate(item?.path);
                                } else {
                                  navigate(item?.path);
                                }
                              }}
                            >
                              <a>
                                <span>{item?.name}</span>
                              </a>
                            </li>
                          )}
                        </>
                      );
                    })}

                  <li onClick={() => setShow(true)}>
                    <a href="">
                      <span>Log Out</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </Nav.Link>
        </>
      ) : (
        <>
          <Nav.Link className="notification">
            <button
              className="tBtn"
              onClick={() => {
                setLoginModal(true);
              }}
            >
              <i className="fa fa-sign-in"></i> Sign In
            </button>
          </Nav.Link>
          <Nav.Link className="notification">
            <button
              onClick={() => navigate(routeName?.SIGN_UP)}
              className="tBtn tBrnLight"
            >
              Sign Up
            </button>
          </Nav.Link>
        </>
      )}
      {isAccess ? (
        <DeletePost
          setShow={setIsAccess}
          show={isAccess}
          title="Alert"
          handleConfirm={() => {
            setLoading(true);
            removeData(storageKey.ACCESS_BASED);
            removeData(storageKey.ACCESS_TOKEN);
            dispatch(partnerAccessPermissions(null));
            const queryParams = new URLSearchParams({
              type:
                permissions?.role === "model" ? "talent" : permissions?.role,
              tab: 1,
            });
            setLoading(false);
            navigate(`${routeName.LIST}?${queryParams.toString()}`, {
              replace: true,
            });
          }}
          text="Are you sure you want to exit permission Access?"
        />
      ) : undefined}
    </Nav>
  );
};

export default AuthButton;

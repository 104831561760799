// Filename - Home.jsx
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../Constants";
import { getData, storageKey, storeData } from "../../Utility/Storage";
import { routeName } from "../../Utility";
import { getUserDetail } from "../../Redux/Services/AuthServices";
import { useDispatch, useSelector } from "react-redux";
import SidebarLayout from "../../layout/SidebarLayout";
import { DashboardPopUp } from "../../Components/Popups";
import { getStripeBalance } from "../../Redux/Services/OtherServices";
import { Tooltip } from "@mui/material";
import { selectedTabAction } from "../../Redux/Actions/AdminActions";
import { JSONS } from "../../Constants/JSON";
import { Modal, Button } from "react-bootstrap";
import Lottie from "lottie-react";
import successJSON from "../../Assets/JSONS/success.json";

export const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useLocation();
  const queryParams = new URLSearchParams(params.search);
  const applicationType = queryParams.get("application-type");
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const { partnerPermissions } = useSelector((state) => state.authReducer);

  const [accountApproval, setAccountApproval] = useState(true);
  const [userData, setUserData] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userLoggedInData, setUserLoggedInData] = useState("");
  const [balanceDetails, setBalanceDetails] = useState("");
  const [copied, setCopied] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2500);
  };

  useEffect(() => {
    getUserData();
    getAvailableBalance();
    if (applicationType == "new") {
      setSuccessModal(true);
    }
  }, []);

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));
      // console.log("getUserDetailgetUserDetail----", res);
      if (res?.status === 200) {
        setUserData(res?.results);
        setAccountApproval(res?.results?.user_data?.profile_approval);
        let userData = await getData(storageKey.USER_DATA);
        if (userData) {
          setUserLoggedInData(JSON.parse(userData));
        }
      }
    } else {
    }
  };

  const getAvailableBalance = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getStripeBalance(body));
      if (res?.status === 200) {
        setBalanceDetails(res?.results || "");
      }
    } else {
    }
  };
  console.log("visible----", isModalVisible);

  const getApprovalStatus = async () => {
    // let accountApproval = await getData(storageKey?.APPROVAL_STATUS);
    // setAccountApproval(JSON?.parse(accountApproval));
    let userData = await getData(storageKey.USER_DATA);
    if (userData) {
      setUserLoggedInData(JSON.parse(userData));
    }
  };

  const handleNavigate = async () => {
    if (
      userData?.user_data?.user_role === 11 ||
      userData?.user_data?.user_role === 15
    ) {
      const queryParams = new URLSearchParams({
        id: userData?.user_data?.user_id,
      });
      navigate(`${routeName.TALENT_PROFILE}?${queryParams.toString()}`);
    } else if (
      userData?.user_data?.user_role === 12 ||
      Number(userData?.user_data?.user_role) === 14
    ) {
      const queryParams = new URLSearchParams({
        id: userData?.user_data?.user_id,
      });
      navigate(`${routeName.CLIENT_PROFILE}?${queryParams.toString()}`);
    }
  };

  const handlePartnerAccessNavigate = async () => {
    if (partnerPermissions?.role !== "client") {
      const queryParams = new URLSearchParams({
        id: partnerPermissions?.user_id,
      });
      navigate(`${routeName.TALENT_PROFILE}?${queryParams.toString()}`);
    } else if (partnerPermissions?.role === "client") {
      const queryParams = new URLSearchParams({
        id: partnerPermissions?.user_id,
      });
      navigate(`${routeName.CLIENT_PROFILE}?${queryParams.toString()}`);
    }
  };

  useEffect(() => {
    // if(!accountApproval){
    //   // if(!loggedInUser?.user_data?.profile_approval){
    //     setIsModalVisible(true)
    //   // }
    // }
    getApprovalStatus();
  }, [loggedInUser]);

  useEffect(() => {
    if (
      userLoggedInData?.user_role &&
      Number(userLoggedInData?.user_role) !== 10
    ) {
      if (
        !userLoggedInData?.profile_approval
        // ||
        // userLoggedInData?.identity_status === 0
      ) {
        setIsModalVisible(true);
      }
    }
  }, [userLoggedInData]);

  let dashboardCards = [
    {
      key: 1,
      title: "View Profile",
      onPress: () => handleNavigate(),
      icon:
        userData?.profile_image?.length && userData?.profile_image[0]?.guid
          ? userData?.profile_image[0]?.guid
          : Images.user,
      hide: true,
      text: "Click To View",
    },
    {
      key: 2,
      title: "New Messages",
      onPress: () => navigate(routeName?.CHAT),
      icon: Images.inboxImage,
      hide: true,
      text: "Click To View",
    },
    {
      key: 3,
      title: "View Saved Items",
      onPress: () => navigate(routeName?.SAVED_COLLECTION),
      icon: Images.followers,
      hide: true,
      text: "Click To View",
    },
    {
      key: 4,
      title: "Social Connections",
      onPress: () => navigate(routeName?.FOLLOW_DETAILS),
      icon: Images.followers,
      hide: true,
      text: "Click To View",
    },
    {
      key: 5,
      title:
        Number(loggedInUser?.user_data?.user_role) === 12 ||
        Number(loggedInUser?.user_data?.user_role) === 14
          ? "Ongoing Projects"
          : "Ongoing Jobs",
      onPress: () => {
        if (
          Number(loggedInUser?.user_data?.user_role) === 12 ||
          Number(loggedInUser?.user_data?.user_role) === 14
        ) {
          storeData(storageKey.SELECTED_JOB_TYPE, "ongoing");
          navigate(`${routeName.CLIENT_PROJECTS}`);
        } else {
          storeData(storageKey.SELECTED_JOB_TYPE, "ongoing");
          navigate(`${routeName.MODEL_JOBS}`);
        }
      },
      icon: Images.ongoingjobs,
      hide: true,
      text: "Click To View",
    },
    {
      key: 6,
      title:
        Number(loggedInUser?.user_data?.user_role) === 12 ||
        Number(loggedInUser?.user_data?.user_role) === 14
          ? "Completed Projects"
          : "Completed Jobs",
      onPress: () => {
        if (
          Number(loggedInUser?.user_data?.user_role) === 12 ||
          Number(loggedInUser?.user_data?.user_role) === 14
        ) {
          storeData(storageKey.SELECTED_JOB_TYPE, "completed");
          navigate(`${routeName.CLIENT_PROJECTS}`);
        } else {
          storeData(storageKey.SELECTED_JOB_TYPE, "completed");
          navigate(`${routeName.MODEL_JOBS}`);
        }
      },
      icon: Images.completedobs,
      hide: true,
      text: "Click To View",
    },
    {
      key: 7,
      title:
        Number(loggedInUser?.user_data?.user_role) === 12 ||
        Number(loggedInUser?.user_data?.user_role) === 14
          ? "Cancelled Projects"
          : "Applied Jobs",
      onPress: () => {
        if (
          Number(loggedInUser?.user_data?.user_role) === 12 ||
          Number(loggedInUser?.user_data?.user_role) === 14
        ) {
          storeData(storageKey.SELECTED_JOB_TYPE, "cancelled");
          navigate(`${routeName.CLIENT_PROJECTS}`);
        } else {
          storeData(storageKey.SELECTED_JOB_TYPE, "proposals");
          navigate(`${routeName.MODEL_JOBS}`);
        }
      },
      icon: Images.canceledjobs,
      hide: true,
      text: "Click To View",
    },
    {
      key: 8,
      title: "Latest Proposals",
      onPress: () => {
        storeData(storageKey.SELECTED_JOB_TYPE, "posted");
        navigate(`${routeName.CLIENT_PROJECTS}`);
      },
      icon: Images.latestProposals,
      hide:
        Number(loggedInUser?.user_data?.user_role) == 12 ||
        Number(loggedInUser?.user_data?.user_role) === 14,
      //   &&
      // loggedInUser?.user_data?.identity_status !== 0,
      text: "Click To View",
    },
    {
      key: 9,
      title: "Jobs",
      onPress: () => navigate(routeName?.JOB_POST),
      icon: Images.calendar,
      hide:
        Number(loggedInUser?.user_data?.user_role) == 12 ||
        Number(loggedInUser?.user_data?.user_role) == 14,
      //   &&
      // loggedInUser?.user_data?.identity_status !== 0,
      text: "Post a Job",
    },
    {
      key: 10,
      title:
        Number(loggedInUser?.user_data?.user_role) === 12 ||
        Number(loggedInUser?.user_data?.user_role) === 14
          ? "Posted Projects"
          : "Direct Jobs",
      onPress: () => {
        if (
          Number(loggedInUser?.user_data?.user_role) === 12 ||
          Number(loggedInUser?.user_data?.user_role) === 14
        ) {
          storeData(storageKey.SELECTED_JOB_TYPE, "posted");
          navigate(`${routeName.CLIENT_PROJECTS}`);
        } else {
          storeData(storageKey.SELECTED_JOB_TYPE, "direct");
          navigate(`${routeName.MODEL_JOBS}`);
        }
      },
      icon: Images.postedjobs,
      hide:
        Number(loggedInUser?.user_data?.user_role) == 12 ||
        Number(loggedInUser?.user_data?.user_role) == 14,
      //   &&
      // loggedInUser?.user_data?.identity_status !== 0,
      text: "Click To View",
    },
    {
      key: 11,
      title: `${balanceDetails?.symbol || "$"} ${
        balanceDetails?.amount || "0"
      }`,
      onPress: () => navigate(routeName?.PAYOUT),
      icon: Images.money,
      hide:
        (Number(loggedInUser?.user_data?.user_role) === 11 ||
          Number(loggedInUser?.user_data?.user_role) === 15) &&
        loggedInUser?.user_data?.profile_approval,
      // &&
      // loggedInUser?.user_data?.identity_status !== 0,
      text: "Available Balance",
    },
    {
      key: 12,
      title: `${balanceDetails?.symbol || "$"} ${
        balanceDetails?.pending_amount || 0
      }`,
      onPress: () => navigate(routeName?.PAYOUT),
      icon: Images.pending,
      hide:
        (Number(loggedInUser?.user_data?.user_role) === 11 ||
          Number(loggedInUser?.user_data?.user_role) === 15) &&
        loggedInUser?.user_data?.profile_approval,
      // &&
      // loggedInUser?.user_data?.identity_status !== 0,
      text: "Pending Balance",
    },
    {
      key: 13,
      title: "Payout History",
      onPress: () => navigate(routeName?.PAYOUT),
      icon: Images.history,
      hide:
        (Number(loggedInUser?.user_data?.user_role) === 11 ||
          Number(loggedInUser?.user_data?.user_role) === 15) &&
        loggedInUser?.user_data?.profile_approval,
      // &&
      // loggedInUser?.user_data?.identity_status !== 0,
      text: "Click To View",
    },
    {
      key: 14,
      title: "Manage Availabilty",
      onPress: () => navigate(routeName?.MANAGE_AVAILABILITY),
      icon: Images.calendar,
      hide:
        Number(loggedInUser?.user_data?.user_role) == 11 ||
        Number(loggedInUser?.user_data?.user_role) == 13 ||
        (Number(loggedInUser?.user_data?.user_role) == 15 &&
          loggedInUser?.user_data?.profile_approval),
      // &&
      // loggedInUser?.user_data?.identity_status !== 0),
      text: "Click To View",
    },
    {
      key: 15,
      title: "Packages",
      onPress: () => navigate(routeName?.PACKAGES),
      icon: Images.packageExpiry,
      hide:
        Number(loggedInUser?.user_data?.user_role) == 11 ||
        Number(loggedInUser?.user_data?.user_role) == 13 ||
        (Number(loggedInUser?.user_data?.user_role) == 15 &&
          loggedInUser?.user_data?.profile_approval),
      //  &&
      // loggedInUser?.user_data?.identity_status !== 0),
      text: "Click To View",
    },
    {
      key: 16,
      title: "MODEL LIST",
      onPress: () => {
        dispatch(selectedTabAction(null));
        const queryParams = new URLSearchParams({
          type: "talent",
          tab: 1,
        });
        navigate(`${routeName.LIST}?${queryParams.toString()}`);
      },
      icon: Images.model,
      hide:
        Number(loggedInUser?.user_data?.user_role) == 14 &&
        loggedInUser?.user_data?.profile_approval,
      // &&
      // loggedInUser?.user_data?.identity_status !== 0,
      text: "Click To View",
    },
    {
      key: 17,
      title: "CLIENT LIST",
      onPress: () => {
        dispatch(selectedTabAction(null));
        const queryParams = new URLSearchParams({
          type: "client",
          tab: 1,
        });
        navigate(`${routeName.LIST}?${queryParams.toString()}`);
      },
      icon: Images.client,
      hide:
        Number(loggedInUser?.user_data?.user_role) == 14 &&
        loggedInUser?.user_data?.profile_approval,
      // &&
      // loggedInUser?.user_data?.identity_status !== 0,
      text: "Click To View",
    },
    {
      key: 18,
      title: "PHOTOGRAPHER LIST",
      onPress: () => {
        dispatch(selectedTabAction(null));
        const queryParams = new URLSearchParams({
          type: "photographer",
          tab: 1,
        });
        navigate(`${routeName.LIST}?${queryParams.toString()}`);
      },
      icon: Images.photographer,
      hide:
        Number(loggedInUser?.user_data?.user_role) == 14 &&
        loggedInUser?.user_data?.profile_approval,
      // &&
      // loggedInUser?.user_data?.identity_status !== 0,
      text: "Click To View",
    },
    {
      key: 19,
      title: "ACTOR LIST",
      onPress: () => {
        dispatch(selectedTabAction(null));
        const queryParams = new URLSearchParams({
          type: "actor",
          tab: 1,
        });
        navigate(`${routeName.LIST}?${queryParams.toString()}`);
      },
      icon: Images.actor,
      hide:
        Number(loggedInUser?.user_data?.user_role) == 14 &&
        loggedInUser?.user_data?.profile_approval,
      // &&
      // loggedInUser?.user_data?.identity_status !== 0,
      text: "Click To View",
    },
    {
      key: 20,
      title: "My Referal Link",
      onPress: () => {},
      icon: Images.referal,
      hide:
        Number(loggedInUser?.user_data?.user_role) == 14 &&
        loggedInUser?.user_data?.profile_approval,
      text: `https://booksculp.com/signup?reffer=SCUL${loggedInUser?.user_data?.profile_id}`,
    },
  ];

  let partnerAccess = [
    {
      key: 6,
      title: "View Profile",
      onPress: () => handlePartnerAccessNavigate(),
      icon: partnerPermissions?.image ? partnerPermissions?.image : Images.user,
      hide: false,
      text: "Click To View",
    },
    {
      key: 1,
      title: "New Messages",
      onPress: () => navigate(routeName?.CHAT),
      icon: Images.inboxImage,
      text: "Click To View",
      hide: partnerPermissions?.manage_chat === "on" ? false : true,
    },
    {
      key: 2,
      title: "View Saved Items",
      onPress: () => navigate(routeName?.SAVED_COLLECTION),
      icon: Images.followers,
      hide: partnerPermissions?.manage_saved_collection === "on" ? false : true,
      text: "Click To View",
    },
    {
      key: 3,
      title: "Social Connections",
      onPress: () => navigate(routeName?.FOLLOW_DETAILS),
      icon: Images.followers,
      hide: partnerPermissions?.manage_follow_details === "on" ? false : true,
      text: "Click To View",
    },
    {
      key: 4,
      title: "Manage Projects",
      onPress: () => {
        navigate(routeName.CLIENT_PROJECTS);
      },
      icon: Images.ongoingjobs,
      hide: partnerPermissions?.role === "client" ? false : true,
      text: "Click To View",
    },
    {
      key: 5,
      title: "Manage Jobs",
      onPress: () => navigate(routeName?.MODEL_JOBS),
      icon: Images.calendar,
      hide: partnerPermissions?.role !== "client" ? false : true,
      text: "Click To View",
    },
    {
      key: 6,
      title: "Post Job",
      onPress: () => navigate(routeName?.JOB_POST),
      icon: Images.calendar,
      hide:
        partnerPermissions?.manage_posts === "on" &&
        partnerPermissions?.role === "client"
          ? false
          : true,
      text: "Post a Job",
    },
    {
      key: 7,
      title: "Post Casting Call",
      onPress: () => navigate(routeName?.CASTING_JOB),
      icon: Images.calendar,
      hide:
        partnerPermissions?.manage_portfolio === "on" &&
        partnerPermissions?.role === "client"
          ? false
          : true,
      text: "Post a Casting Call",
    },
  ];

  const adminDashboard = [
    {
      key: 1,
      title: "TALENTS",
      onPress: () => {
        dispatch(selectedTabAction(null));
        navigate(routeName.MODEL_LIST);
      },
      icon: Images.model,
      text: "Click To View",
    },
    {
      key: 2,
      title: "CLIENTS",
      onPress: () => {
        dispatch(selectedTabAction(null));
        navigate(routeName.CLIENT_LIST);
      },
      icon: Images.client,
      text: "Click To View",
    },
    {
      key: 3,
      title: "PHOTOGRAPHERS",
      onPress: () => {
        dispatch(selectedTabAction(null));
        navigate(routeName.PHOTO_LIST);
      },
      icon: Images.photographer,
      text: "Click To View",
    },
    {
      key: 4,
      title: "ACTORS",
      onPress: () => {
        dispatch(selectedTabAction(null));
        navigate(routeName?.ACTOR_LIST);
      },
      icon: Images.actor,
      text: "Click To View",
    },
    {
      key: 5,
      title: "PARTNERS",
      onPress: () => {
        dispatch(selectedTabAction(null));
        navigate(routeName.PARTNER_LIST);
      },
      icon: Images.partner,
      text: "Click To View",
    },
    {
      key: 6,
      title: "DELETED & PAUSED ACCOUNTS",
      onPress: () => navigate(routeName.DELETED_ACCOUNTS),
      icon: Images.danger,
      text: "Click To View",
    },
    // {
    //   key: 7,
    //   title: "PARTNERS",
    //   onPress: () => {},
    //   icon: Images.partner,
    //   text: "Click To View",
    // },
    {
      key: 8,
      title: "INBOX",
      onPress: () => navigate(routeName?.CHAT),
      icon: Images.inboxImage,
      text: "Click To View",
    },
    {
      key: 9,
      title: "REPORTED USERS/POSTS",
      onPress: () => {
        navigate(routeName.REPORTED_DATA);
      },
      icon: Images.report,
      text: "Click To View",
    },
    {
      key: 10,
      title: "PACKAGES",
      onPress: () => {
        navigate(routeName.MANGE_PACKAGE);
      },
      icon: Images.package,
      text: "Click To View",
    },
    {
      key: 11,
      title: "FAQ",
      onPress: () => {
        navigate(routeName.FAQ_LIST);
      },
      icon: Images.actor,
      text: "Click To View",
    },
    {
      key: 12,
      title: "BLOGS",
      onPress: () => {
        navigate(routeName.MANAGE_BLOGS);
      },
      icon: Images.actor,
      text: "Click To View",
    },
    {
      key: 12,
      title: "BANNERS",
      onPress: () => {
        navigate(routeName.BANNERS);
      },
      icon: Images.actor, //require("https://cdn-icons-png.flaticon.com/512/5171/5171140.png"),
      text: "Click To View",
    },
  ];

  return (
    <SidebarLayout>
      <main>
        <div className="container">
          {!userLoggedInData?.profile_approval && (
            <div className="row">
              <div className="col-md-8">
                <div className="Verificationinprogress wt-dashboardbox wt-insightnoticon">
                  <h2>Notification</h2>
                  <h4>You're All Set! We're Verifying Your Application.</h4>
                  <p>
                  Thanks for applying to Book Sculp! We’ve got all your details and are currently checking everything over. Our team will review your application and get back to you soon with the next steps if your account is approved. If you have any questions in the meantime, don’t hesitate to reach out to us at info@booksculp.com We're excited to connect with you!
                  </p>
                  <button
                    className="edit_app"
                    onClick={() => navigate(routeName?.EDIT_PROFILE)}
                  >
                    Edit Application
                  </button>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="wt-dashboardbox pd-30">
                  <h3>Download Book Sculp App</h3>
                  <div className="downloadIcons">
                    <a href="https://apps.apple.com/in/app/book-sculp/id6444509575">
                      <img
                        src="https://booksculp.com/LIVE/uploads/appstore.png"
                        alt="Book Sculp | Modeling Agency | Modeling Jobs | Modeling agents near me"
                      />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.sculp_agency">
                      <img
                        src="https://booksculp.com/LIVE/uploads/playstore.webp"
                        alt="Book Sculp | Modeling Agency | Modeling Jobs | Modeling agents near me"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          {userLoggedInData?.profile_approval && (
            <div className="row">
              {Number(userLoggedInData?.user_role) === 10
                ? adminDashboard?.map((item) => (
                    <div
                      key={item?.key}
                      className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                    >
                      <div className="wt-insightsitem wt-dashboardbox">
                        <figure className="wt-userlistingimg">
                          <img
                            src={item?.icon}
                            alt="image description"
                            className="mCS_img_loaded"
                          />
                        </figure>
                        <div className="wt-insightdetails">
                          <div className="wt-title">
                            <h3>{item?.title}</h3>
                            <a onClick={item?.onPress}>{item?.text}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : partnerPermissions?.email
                ? partnerAccess?.map((item) => (
                    <>
                      {item?.hide ? undefined : (
                        <div
                          key={item?.key}
                          className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                        >
                          <div className="wt-insightsitem wt-dashboardbox">
                            <figure className="wt-userlistingimg">
                              <img
                                src={item?.icon}
                                alt="image description"
                                className="mCS_img_loaded"
                              />
                            </figure>
                            <div className="wt-insightdetails">
                              <div className="wt-title">
                                <h3>{item?.title}</h3>
                                <a onClick={item?.onPress}>{item?.text}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))
                : dashboardCards?.map((item) => (
                    <>
                      {!item?.hide ? undefined : (
                        <div
                          key={item?.key}
                          className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"
                        >
                          <div className="wt-insightsitem wt-dashboardbox">
                            <figure className="wt-userlistingimg">
                              <img
                                src={item?.icon}
                                alt="image description"
                                className="mCS_img_loaded"
                              />
                            </figure>
                            <div className="wt-insightdetails">
                              <div className="wt-title">
                                <h3>{item?.title}</h3>
                                <a onClick={item?.onPress}>{item?.text}</a>
                                {item?.key === 20 ? (
                                  <Tooltip
                                    title={
                                      copied ? "Copied!" : "Copy to Clipboard"
                                    }
                                  >
                                    <span
                                      onClick={() => handleCopy(item?.text)}
                                    ></span>
                                    <i
                                      onClick={() => handleCopy(item?.text)}
                                      class="fa fa-clipboard"
                                      aria-hidden="true"
                                    ></i>
                                  </Tooltip>
                                ) : undefined}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
            </div>
          )}
        </div>
      </main>
      {applicationType == "new" && (
        <Modal
          show={successModal}
          onHide={() => setSuccessModal(false)}
          // onHide={() => setSuccessModal(false)}
          centered
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>
              You're All Set! We're Verifying Your Application.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <Lottie
              animationData={successJSON}
              style={{
                position: "relative",
                width: "100%",
                top: -135,
              }}
            />
            <div className="success-modal-text">
              <p>
                Thanks for applying to <strong>Book Sculp!</strong> We’ve got
                all your details and are currently checking everything over. Our
                team will review your application and get back to you soon with
                the next steps if your account is approved.
              </p>
              <p>
                If you have any questions in the meantime, don’t hesitate to
                reach out to us at{" "}
                <a href="mailto:info@booksculp.com">info@booksculp.com</a>.
                We're excited to connect with you!
              </p>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="primary" onClick={() => setSuccessModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {isModalVisible ? (
        <DashboardPopUp
          setVisible={setIsModalVisible}
          visible={isModalVisible}
          text={
            !userLoggedInData?.profile_approval
              ? "Thanks for applying to Book Sculp! We’ve got all your details and are currently checking everything over. Our team will review your application and get back to you soon with the next steps if your account is approved. If you have any questions in the meantime, don’t hesitate to reach out to us at info@booksculp.com We're excited to connect with you!."
              : userLoggedInData?.identity_status === 0
              ? "You must verify your identity to get booked by a client"
              : ""
          }
          title={
            !userLoggedInData?.profile_approval
              ? "You're All Set! We're Verifying Your Application."
              : userLoggedInData?.identity_status === 0
              ? "ID Verification Missing"
              : ""
          }
        />
      ) : undefined}
    </SidebarLayout>
  );
};

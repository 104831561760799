import React, { useEffect, useState } from "react";
import { Routing } from "./Routing";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./Assets/css/normalize.css";
import "./Assets/css/scrollbar.css";
import "./Assets/css/fontawesome/fontawesome-all.css";
import "./Assets/css/font-awesome.min.css";
import "./Assets/css/owl.carousel.min.css";
import "./Assets/css/linearicons.css";
import "./Assets/css/jquery-ui.css";
import "./Assets/css/tipso.css";
import "./Assets/css/chosen.css";
import "./Assets/css/main.css";
import "./Assets/css/dashboard.css";
import "./Assets/css/color.css";
import "./Assets/css/transitions.css";
import "./Assets/css/style.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Assets/css/responsive.css";
import "./Message.scss";
import { useDispatch } from "react-redux";
import {
  getCurrentLocationApi,
  getOptionsData,
} from "./Redux/Services/AuthServices";
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import { auth, db } from "./Utility/firebase";
import { setCurrentLocation } from "./Redux/Actions/AuthActions";
import { getData, storageKey, storeData } from "./Utility/Storage";
import ct from "countries-and-timezones";
import { showToast } from "./Utility";
import { sendNewsLetter } from "./Redux/Services/OtherServices";
import { CookieConsent } from "./Components/Popups/CookieConsent";

function App() {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [show, setShow] = useState(true);
  const getCountry = async () => {
    try {
      async function getLocationData() {
        // Get the timezone using Intl API
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Attempt to map the timezone to a country using the library
        const countryData = ct.getCountryForTimezone(timezone);
        const country = countryData ? countryData.name : "Unknown";

        return { country, timezone };
      }

      async function fetchLocationData() {
        const { country, timezone } = await getLocationData();
        return { country, timezone };
      }
      const response = await fetchLocationData();

      try {
        let body = {
          country: response?.country,
          timezones: response?.timezone,
        };

        const res = await dispatch(getCurrentLocationApi(body));

        if (res?.status === 200) {
          let params = {
            id: res?.results?.id,
            phonecode: res?.results?.phonecode,
            timeZone: res?.results?.time_zone,
            country: response?.country,
          };
          storeData(storageKey?.COUNTRY_ID, res?.results?.id);
          dispatch(setCurrentLocation(params));
        }
      } catch (error) {}
    } catch (error) {
      console.error("Error fetching location:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchCountry = async () => {
      await getCountry();
    };
    fetchCountry();
  }, []);

  useEffect(() => {
    dispatch(getOptionsData());
  }, [dispatch]);

  useEffect(() => {
    let userId;
    let statusInterval;

    const updateUserStatus = async (status) => {
      if (userId) {
        try {
          await setDoc(
            doc(db, "userStatus", userId),
            { status },
            { merge: true }
          );
        } catch (error) {
          console.error("Error updating status:", error.message);
        }
      }
    };

    const handleBeforeUnload = () => {
      updateUserStatus("offline");
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        updateUserStatus("online");
        clearInterval(statusInterval);
        statusInterval = setInterval(() => {
          updateUserStatus("online");
        }, 10000); // Every 10 seconds
      } else {
        updateUserStatus("away");
        clearInterval(statusInterval);
      }
    };

    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        userId = user.uid;
        updateUserStatus("online");

        // Listen for tab visibility changes
        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Handle page/tab unload
        window.addEventListener("beforeunload", handleBeforeUnload);

        // Periodically confirm user is online while the tab is active
        statusInterval = setInterval(() => {
          updateUserStatus("online");
        }, 10000);
      } else {
        setIsLoggedIn(false); // Not logged in
        updateUserStatus("offline");
        userId = null;
      }
    });
    return () => {
      unsubscribeAuth();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      clearInterval(statusInterval);
      updateUserStatus("offline"); // Make sure status is updated to offline on cleanup
    };
  }, []);

  // const handleLogout = async () => {
  //   try {
  //     // Set user offline before logging out
  //     const user = auth.currentUser;
  //     if (user) {
  //       await setDoc(doc(db, 'userStatus', user.uid), { status: 'offline' }, { merge: true });
  //     }
  //     // Proceed with logout
  //     await signOut(auth);
  //   } catch (error) {
  //     console.error('Error signing out:', error.message);
  //   }
  // };

  //  console.log = () => {}

  useEffect(() => {
    getUserId();
  }, []);

  const getUserId = async () => {
    const userId = await getData(storageKey?.USER_ID);
    if (userId) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  };

  return (
    <>
      <Routing />
      {showPopup && <Popup />}
      {/* <CookieConsent setShow={setShow} show={show} /> */}
    </>
  );
}

export default App;

export const Popup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  // Show popup after 15 seconds
  useEffect(() => {
    handleSubscribeNewsLetter();
  }, []);

  const handleSubscribeNewsLetter = async () => {
    let hide = await getData(storageKey?.SUBSCRIBE_HIDE);
    let user_id = await getData(storageKey?.USER_ID);
    if (user_id && hide == "closed") {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 15000); // 15 seconds

      // Cleanup timeout on component unmount
      return () => clearTimeout(timer);
    }
    //  else if (hide == "subscribed")
  };

  const handleClose = () => {
    setIsVisible(false);
    storeData(storageKey?.SUBSCRIBE_HIDE, "closed");
    // const timer = setTimeout(() => {
    //   setIsVisible(true);
    // }, 30000); // 15 seconds

    // // Cleanup timeout on component unmount
    // return () => clearTimeout(timer);
  };

  if (!isVisible) return null; // Don't render if not visible

  const handleSubscribe = async () => {
    if (!email) {
      showToast("Please enter the email", "error");
    } else {
      var body = {
        user_email: email,
      };
      let res = await dispatch(sendNewsLetter(body));
      if (res?.status == 200) {
        storeData(storageKey?.SUBSCRIBE_HIDE, "subscribed");
        if (email.trim()) {
          showToast(
            `Thank you for subscribing! We'll send updates to ${email}.`,
            "info"
          );
          setIsVisible(false);
          setEmail("");
        } else {
          alert("Please enter a valid email address.");
        }
      }
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <h4>Want to know more?</h4>
        <small>
          Subscribe to our newsletter below for the latest updates, resources,
          and more!
        </small>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
        <div className="popup-buttons">
          <button onClick={handleSubscribe}>Subscribe</button>
          <button onClick={handleClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

// import { showToast } from "../Utility";
import { showToast } from "../Utility";
import { getData, storageKey } from "../Utility/Storage";
import * as Url from "./Url";
import axios from "axios";
var errorToast = false;
export const get = async (url, token, hide = false) => {
  var headers;
  let getToken = await getData(storageKey?.AUTH_TOKEN);
  let getToken2 = await getData(storageKey?.ACCESS_TOKEN);
  if (getToken == "" || getToken == null || getToken == undefined) {
    headers = {
      "Content-Type": "application/json",
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      // "Access-Control-Allow-Origin": "http://localhost:3000/",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET, POST",
      "Access-Control-Allow-Headers": "*",
      Authorization: getToken2 || getToken,
    };
  }
  const completeUrl = Url.BASE_URL + url;
  try {
    const response = await axios.get(Url.BASE_URL + url, {
      headers,
    });
    if (response?.status == 200) {
      const message = response.message;
      if (!hide) {
        showToast(message, "success");
      }
    } else {
      const message = response?.message;
      showToast(message, "error");
    }
    return response?.data;
  } catch (error) {
    if (error) {
      // console.log("Get Service error--", error);

      // if (error?.code == "ERR_NETWORK") {
      //   showToast("Internet connection not available.", "error");
      // } else 
      if (!hide) {
        showToast(error, "error");
      }

      return error;
    }
  }
};

export const post = async (url, token, body, hide = false, error = true) => {
  let getToken = await getData(storageKey?.AUTH_TOKEN);
  let getToken2 = await getData(storageKey?.ACCESS_TOKEN);
  var headers;
  if (getToken) {
    headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      // "Access-Control-Allow-Origin": "http://localhost:3000/",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET, POST",
      "Access-Control-Allow-Headers": "*",
      Authorization: getToken2 || getToken,
    };
  }
  // console.log("headersheadersheaders---", headers);
  const completeUrl = Url.BASE_URL + url;
  let data = JSON.stringify(body);
  // console.log("Post service completeUrl----", completeUrl);
  try {
    const response = await axios.post(Url.BASE_URL + url, data, {
      headers: !getToken ? "" : headers,
    });
    console.log(response, "Post service");
    if (response?.data?.status == 200) {
      const message = response.data?.message;
      if (!hide) {
        showToast(message, "success");
      }
    } else {
      const message = response.data?.message;
      if (!hide) {
        showToast(message  , "error");
      }
    }
    return response.data;
  } catch (err) {
    if (err) {
      // if (err?.code == "ERR_NETWORK") {
      //   showToast("Internet connection not available.", "error");
      // } else 
      
      if (!hide) {
        showToast(err?.response?.data?.message || err?.message, "error");
      }

      console.log("Post Service err--", err);
      // error ? showToast(err?.message, "error") : null;
      return err;
    }
  }
};

export const formDataPost = async (
  url,
  token,
  body,
  hide = false,
  error = true
) => {
  let getToken = await getData(storageKey?.AUTH_TOKEN);
  let getToken2 = await getData(storageKey?.ACCESS_TOKEN);
  var headers;
  if (getToken == "" || getToken == null || getToken == undefined) {
    headers = {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    };
  } else {
    headers = {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: getToken2 || getToken,
    };
  }
  try {
    const response = await axios.post(Url.BASE_URL + url, body, {
      headers,
    });
    if (response?.status === 200) {
      const message = response.data?.message;
      if (!hide) {
        showToast(message, "success");
      }
    } else {
      const message = response.data?.message;
      showToast(message, "error");
    }

    return response.data;
  } catch (err) {
    if (err) {
      // if (err?.code == "ERR_NETWORK") {
      //   showToast("Internet connection not available.", "error");
      // } else 
      
      if (!hide) {
        showToast(err?.response?.data?.message || err?.message, "error");
      }
      // console.log("Post Service err--", err);
      // error ? showToast(err?.message, "error") : null;
      return err;
    }
  }
};
// export const formDataPost = async (url, token, body, hide = false) => {
//   console.log("formDataPost data--", url, body);
//   var headers;
//   if (token == "" || token == null || token == undefined) {
//     headers = {
//       "Content-Type": "multipart/form-data",
//       Accept: "application/json",
//     };
//   } else {
//     headers = {
//       "Content-Type": "multipart/form-data",
//       Accept: "application/json",
//       Authorization: token,
//     };
//   }
//   const config = {
//     onUploadProgress: (progressEvent) => {
//       const { loaded, total } = progressEvent;
//       let percent = Math.floor((loaded * 100) / total);
//       console.log(`${loaded}kb of ${total}kb | ${percent}%`); // just to see whats happening in the console
//     },
//     headers: {
//       headers,
//     },
//   };
//   const completeUrl = Url.BASE_URL + url;
//   console.log("completeUrl", completeUrl);
//   const postResponse = await axios
//     .post(completeUrl, { data: body }, config)
//     .then((res) => {
//       console.log("resresersersererse------", res);
//       // setPercentage(percent),
//       // () => {
//       //   setTimeout(() => {
//       //     setPercentage(0);
//       //   }, 1000);
//       // };
//     })
//     .catch((error) => {
//       console.error("Upload Error:", error);
//     });
//   console.log("resresresres------", postResponse);

//   // try {
//   // const res = await fetch(completeUrl, {
//   //   method: "POST",
//   //   headers: headers,
//   //   body: body,
//   // });
//   // let response = await res.json();
//   // if (response?.status == 200) {
//   //   const message = response.message;
//   //   if (message) {
//   //     !hide ? showToast(message, "success") : null;
//   //   }
//   // } else {
//   //   const message = response.message;
//   //   if (message) {
//   //     showToast(message, "error");
//   //   }
//   // }
//   // const res = axios.post(completeUrl, body, config)
//   //   .then(async (x) => {
//   //     console.log("xxxxxxxx------", x);
//   //     let response = await res.json();
//   //     if (response?.status == 200) {
//   //       const message = response.message;
//   //       if (message) {
//   //         !hide ? showToast(message, "success") : null;
//   //       }
//   //     } else {
//   //       const message = response.message;
//   //       if (message) {
//   //         showToast(message, "error");
//   //       }
//   //     }
//   //     return response;
//   //     // setPercentage(percent), () => {
//   //     // setTimeout(() => {
//   //     // setPercentage(0)
//   //     // }, 1000);
//   //     // }
//   //   })
//   //   .catch((error) => {
//   //     console.error("Upload Error:", error);
//   //   });

//   // } catch (error) {
//   //   if (error) {
//   //     console.log("Some issue while posting form data - ", error);
//   //     // showToast(error, "error");
//   //     return error;
//   //   }
//   // }
// };

export const put = async (url, token, body) => {
  var headers;
  let getToken = await getData(storageKey?.AUTH_TOKEN);
  let getToken2 = await getData(storageKey?.ACCESS_TOKEN);
  if (getToken == "" || getToken == null || getToken == undefined) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  } else {
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getToken2 || getToken,
    };
  }
  const completeUrl = Url.BASE_URL + url;
  // console.log("completeUrl", completeUrl);
  let data = JSON.stringify(body);
  try {
    const response = await fetch(completeUrl, {
      method: "PUT",
      headers,
      body: data,
    });
    if (response?.status == 200) {
      const message = response.message;
      if (message) {
        // !hide ? showToast(message, "success") : null;
      }
    } else {
      const message = response.message;
      if (message) {
        // showToast(message, "error");
      }
    }
    return response;
  } catch (error) {
    if (error) {
      // console.log("Put Service error--", error);
      // showToast(error, "error");
      return error;
    }
  }
};

export const deleteService = async (url, token, body, guest) => {
  var headers;
  let getToken = await getData(storageKey?.AUTH_TOKEN);
  let getToken2 = await getData(storageKey?.ACCESS_TOKEN);
  if (getToken == "" || getToken == null || getToken == undefined) {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  } else {
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getToken2 || getToken,
    };
  }
  const completeUrl = Url.BASE_URL + url;
  // console.log("Delete servive completeUrl---", completeUrl);
  let data = JSON.stringify(body);
  try {
    const res = await fetch(completeUrl, {
      method: "DELETE",
      headers,
      body: data,
    });
    let response = await res.json();
    if (response?.status == 200) {
      const message = response.message;
      if (message) {
        // !hide ? showToast(message, "success") : null;
      }
    } else {
      const message = response.message;
      if (message) {
        // showToast(message, "error");
      }
    }
    return response;
  } catch (error) {
    if (error) {
      // console.log("Delete Service error--", error);
      // showToast(error, "error");
      return error;
    }
  }
};

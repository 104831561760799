import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import OTPInput from "otp-input-react";

export const AdminVerify = (props) => {
  const { show, setShow, handleLogin } = props;
  const [otp, setOtp] = useState("");

  const handleOtpSubmit = () => {
    console.log("Entered OTP:", otp);
    // Add your OTP validation or API logic here
    setShow(false); // Close the modal after submission
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Two Factor Authentication
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          If you want to login as a Book Sculp Admin. Please Enter one time
          password sent to your mobile number.
        </p>

        <div className="optInput">
          <OTPInput
            value={otp}
            onChange={setOtp}
            autoFocus={true}
            OTPLength={6}
            otpType="number"
            disabled={false}
            inputStyle={{ padding: 0 }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-center">
        <Button
          onClick={() => {
            handleLogin(otp);
          }}
        >
          {"Verify"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
